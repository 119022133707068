import { useSidebarStore } from '@/modules/editor/modules/sidebar/stores/sidebar';

export const useInteractionAction = () => {
  const sidebarStore = useSidebarStore();

  const isBlockedByInteraction = computed(() => sidebarStore.mode === 'interaction');
  return {
    isBlockedByInteraction,
  };
};
