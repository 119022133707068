import type { Component, Section } from '@/modules/editor/modules/common/utils/types';
import { convertComponentToJSON, loopComponent } from '@/modules/editor/modules/common/utils/section/component';
import { cloneDeepObject } from '@/utils/common';
import type {
  AnimationConfig,
  AnimationType,
} from '@/modules/editor/modules/settings/components/controls/animation/types';
import type { ScreenType } from '@gem/control';

export const useCleanComponentData = () => {
  const { isAnimationEnable } = useAnimationSetting();

  const cleanSectionData = (section: Section) => {
    const component = section?.component;
    if (!component) return null;

    const jsonComponent = convertComponentToJSON(component);
    if (!jsonComponent || !jsonComponent.uid || !jsonComponent.tag) return null;

    return cloneDeepObject({
      ...section,
      component: JSON.stringify(cleanAdvancedSetting(jsonComponent)),
    });
  };

  const cleanAdvancedAnimationSetting = (component: Record<string, any>) => {
    if (!component?.advanced?.animation) return;

    const isEnableAnimation = isAnimationEnable(component.advanced.animation);
    if (!isEnableAnimation) delete component.advanced.animation;
  };

  const cleanAdvancedSetting = (component: Component) => {
    if (!component) return component;
    loopComponent(component, (component) => {
      cleanAdvancedAnimationSetting(component);
    });

    return cloneDeepObject(component);
  };

  return {
    cleanAdvancedSetting,
    cleanSectionData,
  };
};

export const useAnimationSetting = () => {
  const getAnimationType = (settings: AnimationConfig, type: 'appear' | 'hover'): AnimationType | 'none' => {
    return settings?.triggerConfig?.[type]?.animation ?? 'none';
  };

  const getSettingsByDevice = (animation: Record<ScreenType, AnimationConfig>, device: ScreenType): AnimationConfig => {
    if (device === 'desktop') return animation?.desktop;
    else if (device === 'tablet') return animation?.tablet ?? animation?.desktop;
    else if (device === 'mobile') return animation?.mobile ?? animation?.tablet ?? animation?.desktop;

    return animation?.desktop;
  };

  const isAnimationEnableByDevice = (setting: AnimationConfig, device: ScreenType) => {
    const appearDesktop = getAnimationType(setting, 'appear');
    const hoverDesktop = getAnimationType(setting, 'hover');

    let additionalCondition = true;
    if (device === 'desktop') additionalCondition = hoverDesktop !== 'none';

    return setting?.enabled && (appearDesktop !== 'none' || additionalCondition);
  };

  const isAnimationEnable = (animation: Record<ScreenType, AnimationConfig>): boolean => {
    const appearDesktop = getAnimationType(getSettingsByDevice(animation, 'desktop'), 'appear');
    const hoverDesktop = getAnimationType(getSettingsByDevice(animation, 'desktop'), 'hover');
    const appearTablet = getAnimationType(getSettingsByDevice(animation, 'tablet'), 'appear');
    const appearMobile = getAnimationType(getSettingsByDevice(animation, 'mobile'), 'appear');

    return (
      (animation?.desktop?.enabled && (appearDesktop !== 'none' || hoverDesktop !== 'none')) ||
      (animation?.tablet?.enabled && appearTablet !== 'none') ||
      (animation?.mobile?.enabled && appearMobile !== 'none')
    );
  };

  return {
    isAnimationEnable,
    getAnimationType,
    isAnimationEnableByDevice,
  };
};
