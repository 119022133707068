import useEditorMode from '@/composables/useEditorMode';
import useNotification from '@/composables/useNotification';
import { event } from '@/modules/editor/modules/common/use-cases/event';
import type {
  Component,
  ComponentConfig,
  ComponentPreset,
  InitComponentType,
} from '@/modules/editor/modules/common/utils/types';
import useBuildWithSection from '@/modules/editor/modules/sidebar/hooks/useBuildWithSection';
import { useSurveyShopMeta } from '@/modules/survey/hooks/useSurveyShopMeta';
import { cloneDeepObject } from '@/utils/common';
import useBuilderConfigStore from '../../component-preset/stores/builderConfig';
import { emitSelectModal } from '../../preview/utils/emitToIframe';
import { useSaleFunnel } from '../../sale-funnels/hooks/useSaleFunnel';
import { useSyncProductProperties } from '../../settings/hooks/useSyncProductProperties';
import { controlChange } from '../../settings/use-cases/control';
import { handleTrackingSearchResult } from '../../sidebar/hooks/useComponentPresetList';
import useEditorStore from '../stores/editor';
import useSectionStore from '../stores/section';
import { cacheGetComponentTagByComponentUid, cacheGetSectionCidByComponentUid } from '../use-cases/cache';
import {
  moveComponentToNewSection,
  sectionCreate,
  sectionMove,
  sectionMoveComponent,
  sectionUpdateComponent,
} from '../use-cases/section';
import { sentryCaptureException } from '../use-cases/sentry';
import {
  ADD_SECTION_IMAGE_TO_LAYOUT_ID,
  MODAL_TAG,
  ROOT_TAG,
  ROW_TAG,
  SECTION_TAG,
  STICKY_TAG,
  TEXT_ELEMENTS,
} from '../utils/const';
import {
  convertComponentToJSON,
  getComponentByUid,
  getFlowPages,
  getNotAppendTags,
  getParentComponentByUid,
  initNewComponent,
  initNewPreset,
  updateComponentUsingCommand,
  getAllChildrensFlowTags,
  generateNameFlowTags,
  changeActiveCoupon,
  changePresetRowMobile,
} from '../utils/section/component';
import { initNewSection } from '../utils/section/section';
import usePreventAppendTag from './usePreventAppendTag';
import { usePublishLimitation } from './usePublishLimitation';
import { useUpdateProductBadge } from './useUpdateProductBage';
import useInsertTemplate from '../../library/hooks/useInsertTemplate';
import type { ThemePageType } from '@gem/common';
import { usePageName } from '../../library/utils';
import { useInteractionAction } from '@/modules/interactions/hooks/useInteractionAction';

const OFFSET = 5;
const MAX_CLIENT_Y = 75;
const MAX_COLUMNS = 6;

const dropElementHereHtml = () => {
  const { buildWithSectionMode } = useBuildWithSection();

  return `
  <div
    class="gp-border-box gp-flex gp-h-4 gp-w-full gp-items-center gp-justify-center gp-gap-2 gp-overflow-hidden gp-whitespace-nowrap gp-px-2 gp-font-medium"
    >
    <div class="gp-flex gp-h-4 gp-w-4 gp-items-center gp-justify-center">
      <svg
        width="15"
        height="15"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.33337 4.33333V0.333328H5.66671V4.33333H9.66671V5.66666H5.66671V9.66666H4.33337V5.66666H0.333374V4.33333H4.33337Z"
          fill="#3C67FF"
        />
      </svg>
    </div>
    <div>Drop ${buildWithSectionMode.value ? 'section' : 'element'} here</div>
  </div>
`;
};

type PlaceHolder = {
  uid: string;
  appendChild: boolean;
  appendBeforeAfter?: boolean;
  appendLeftRight?: boolean;
  top: number;
  left: number;
  width: number;
  height: number;
  afterId?: string;
  isSlot?: boolean;
  direction?: 'before' | 'after' | 'children' | 'left' | 'right';
  margin?: {
    top: string;
    bottom: string;
  };
  productId?: string;
  articleId?: string;
  parentTag?: string;
  tag?: string;
  isThemeSection?: boolean;
  isExceededSection?: boolean;
  isShopifySection?: boolean;
  marqueeItemKey?: string;
};

type rectPositionPlaceHolder = {
  direction: 'before' | 'after' | 'children' | 'left' | 'right';
  pTop: number;
  pLeft: number;
  pWidth: number;
  pHeight?: number;
  placeholderHeight: number;
  cWidth: number;
  cHeight: number;
  isSlot?: boolean;
  isComponentDragging?: boolean;
  dataId?: string;
  dataAfterId?: string;
  appendChild?: boolean;
  productId?: string;
  articleId?: string;
  parentTag?: string;
  tag: string;
  isThemeSection?: boolean;
  isExceededSection?: boolean;
  isShopifySection?: boolean;
  marqueeItemKey?: string;
};

type RectDraggingIsDropOutbound = '' | 'top' | 'bottom' | 'anywhere';
type RectDragging = {
  top: number;
  height: number;
  cachedData: {
    modalAnchorsLength: number;
    sectionAnchorsLength: number;
    isSectionAnchors: boolean;
    isSectionBetweenAnchors: boolean;
    isDropOutbound: RectDraggingIsDropOutbound;
    isExistSticky: boolean;
  };
};

type BoundingRectStick = {
  top: number;
  left: number;
  width: number;
  height: number;
};

type PositionDropSticky = '' | 'top' | 'bottom';

const renderLimitLabel = () => {
  return `<div class="limitation-error-content">
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M15 8.5C15 12.366 11.866 15.5 8 15.5C4.13401 15.5 1 12.366 1 8.5C1 4.63401 4.13401 1.5 8 1.5C11.866 1.5 15 4.63401 15 8.5ZM8 11C7.58579 11 7.25 11.3358 7.25 11.75C7.25 12.1642 7.58579 12.5 8 12.5C8.41421 12.5 8.75 12.1642 8.75 11.75C8.75 11.3358 8.41421 11 8 11ZM8.5 4.5C8.5 4.22386 8.27614 4 8 4C7.72386 4 7.5 4.22386 7.5 4.5V9.5C7.5 9.77614 7.72386 10 8 10C8.27614 10 8.5 9.77614 8.5 9.5V4.5Z" fill="#F44336"/>
  </svg>
    <span>Page has reached Shopify’s 25 section-limit</span>
  </div>`;
};

const fullWidthComponentTags: string[] = ['Row', 'HeroBanner', 'Carousel'];

const sectionFullWidthForComponentTags = ['Marquee'];

const noSpacingComponentTags: string[] = ['HeroBanner'];

const canNotDropHere: Record<string, { centerText: string }> = {
  Marquee: {
    centerText: 'cannot be dropped into Marquee',
  },
  'Marquee Item': {
    centerText: 'cannot be dropped into Marquee',
  },
};

const selector = {
  iframe: '.iframe',
  iframeScreens: '.editor-preview-screens',
  fakeComponent: '#draggable-fake',
};

export default function useDraggable() {
  //==================== HOOKS ==========================
  const editorStore = useEditorStore();
  const builderConfigStore = useBuilderConfigStore();
  const sectionStore = useSectionStore();
  const editMode = useEditorMode();
  const { checkSectionLimitation } = usePublishLimitation();
  const limitSection = computed(() => !checkSectionLimitation());
  const { updateDropElementCount } = useSurveyShopMeta();
  const { isPostPurchasePage } = useSaleFunnel();
  const { isPreventAppend } = usePreventAppendTag();
  const { isBlockedByInteraction } = useInteractionAction();

  //==================== STATE ==========================

  const state: {
    newPreset: null | ComponentPreset;
    timeoutDragging: NodeJS.Timeout | null;
    timeoutAutoScroll: NodeJS.Timeout | null;
    idRequestAnimationFrame: number | null;
    component: null | Component;
    rectDragging: RectDragging | null; //locked dropping area
    lastClientY?: number;
    isAutoScroll?: boolean;
    rectStickies?: BoundingRectStick[];
    isHoverInSticky?: boolean;
  } = {
    newPreset: null,
    timeoutDragging: null,
    timeoutAutoScroll: null,
    idRequestAnimationFrame: null,
    component: null,
    rectDragging: null,
    isAutoScroll: true,
    rectStickies: [],
    isHoverInSticky: false,
  };
  const editingPageType = computed(() => editorStore.getEditingPageType);
  const isEditThemeSection = computed(() => editorStore.getIsEditThemeSection);
  const buildWithSectionDraggingInfo = computed(() => sectionStore.getBuildWithSectionDraggingInfo);
  const isDragStickySection = computed(() => state.newPreset?.isStickySection);

  //==================== Mouse Down ==========================
  const listenMouseDownIframe = (event: MouseEvent) => {
    const e = event || window.event;
    if (e.button !== 0) return; // allow only left click

    onMouseDown(event, 200, 'iframe');
  };

  const onMouseDown = (event: any, delay = 200, area: 'document' | 'iframe') => {
    if (!event.target) return;
    if (isTextOrHeadingFocus(event.target)) return;
    if (isToolbar(event.target)) return;

    //@ts-ignore
    document.activeElement?.blur && document.activeElement?.blur();
    // Start drag & drop
    if (state.timeoutDragging) clearTimeout(state.timeoutDragging);
    state.timeoutDragging = setTimeout(() => {
      if (editMode.value == 'dragdrop') {
        const $iframe = document.body.querySelector<HTMLIFrameElement>(selector.iframe);
        let $iframeDoc = null;
        let $iframeBody = null;
        if ($iframe) {
          $iframeDoc = $iframe.contentDocument ?? ($iframe.contentWindow as any).document;
          $iframeBody = $iframeDoc.querySelector('body');
        }

        const $preventSection = getPreventSectionFrom(event.target);

        const $target = $preventSection ?? event.target;
        const $sidebarItem = $target.closest('.gemx-preset-item[data-preset-id]');
        const $component = $target.closest(
          '[data-component-type="component"]:not([data-component-no-drag-drop="true"])',
        );
        const $buildWithSection = $target.closest('[data-build-with-section-id]');
        if ($component?.getAttribute('data-component-tag') == SECTION_TAG && isEditThemeSection.value) {
          return;
        }
        const $toolbar = $target.closest('.gemx-toolbar-name, .gemx-toolbar-movename');
        const componentPresets = builderConfigStore.getComponentPresets;

        // Add grabbing cursor to current target

        // Add data drag & drop to state
        if ($sidebarItem) {
          $sidebarItem.classList.remove('cursor-grab');
          $sidebarItem.classList.add('cursor-grabbing');
          const presetId = $sidebarItem.getAttribute('data-preset-id'); // drag on sidebar
          state.newPreset = componentPresets[presetId];
        } else if ($component) {
          $component.style.cursor = 'grabbing';
          const noDragDrop = $component.getAttribute('data-component-no-drag-drop'); // drag on sidebar
          if (noDragDrop) {
            return;
          }

          const componentUid = $component.getAttribute('data-uid'); // drag on iframe
          const sectionCid = cacheGetSectionCidByComponentUid(componentUid);
          const section = sectionStore.getItemByCid(sectionCid);

          if (section) {
            const jsonComponent = convertComponentToJSON(section?.component || '');
            if (jsonComponent) {
              const component = getComponentByUid(jsonComponent, componentUid);
              if (component) {
                state.component = component;
              }
            }
          }
        } else if ($toolbar) {
          const componentUid = $toolbar.getAttribute('data-uid'); // drag on iframe
          const sectionCid = cacheGetSectionCidByComponentUid(componentUid);
          const section = sectionStore.getItemByCid(sectionCid);
          if (section) {
            const jsonComponent = convertComponentToJSON(section?.component || '');
            if (jsonComponent) {
              const component = getComponentByUid(jsonComponent, componentUid);
              if (component) {
                state.component = component;
              }
            }
          }
        } else if ($buildWithSection) {
          const jsonComponent: InitComponentType = {
            tag: 'Section',
            editorConfigs: { type: 'loading' },
            childrens: [
              {
                tag: 'Col',
                childrens: [
                  {
                    tag: 'Row',
                  },
                ],
              },
            ],
          };
          state.newPreset = {
            id: $buildWithSection.getAttribute('data-build-with-section-id'),
            name: {
              en: $buildWithSection.getAttribute('data-build-with-section-name'),
            },
            icon: {
              desktop: 'https://ucarecdn.com/e124169a-c5b7-4056-85c3-5a5aeb5264a6/-/format/auto/',
            },
            components: [jsonComponent],
            isStickySection: buildWithSectionDraggingInfo.value?.category?.name
              ?.toLocaleLowerCase()
              .includes(STICKY_TAG.toLocaleLowerCase()),
          };
        } else {
          sentryCaptureException(
            'useDraggable',
            'onMouseDown: Can not find component or tag',
            {
              event,
            },
            {
              level: 'error',
            },
          );
        }

        // Start drag & drop
        if ($sidebarItem || $component || $toolbar || $buildWithSection) {
          if ($iframe) {
            // Disable user-select when drag
            if (state.component) {
              const component = state.component;
              if ($iframeBody) {
                const $storefront = $iframeBody.querySelector('#storefront');
                if ($storefront) {
                  $storefront.style.userSelect = 'none';
                  const $currentComponents = $storefront.querySelectorAll(`[data-uid="${component.uid}"]`);
                  for (const $currentComponent of $currentComponents) {
                    if ($currentComponent) {
                      // Add placeholder current section
                      const $placeholderComponent = $currentComponent.cloneNode(true);
                      $placeholderComponent.classList.add('placeholder-component-dragging');
                      $currentComponent.after($placeholderComponent);

                      const rect = $currentComponent?.getBoundingClientRect();
                      $placeholderComponent.setAttribute('style', $currentComponent.getAttribute('style'));
                      // $placeholderComponent.style.display = 'block';
                      $placeholderComponent.style.height = `${rect.height}px`;
                      $placeholderComponent.style.backgroundColor = '#EEEEEE';
                      $placeholderComponent.style.opacity = '0.4';

                      if ($component) {
                        const productId =
                          $component.closest('[data-component-tag="Product"]')?.getAttribute('data-product-id') ?? '';
                        const articleId =
                          $component.closest('[data-component-tag="Article"]')?.getAttribute('data-article-id') ?? '';
                        $placeholderComponent.setAttribute('data-product-id', productId);
                        $placeholderComponent.setAttribute('data-article-id', articleId);
                      }

                      // Hide current section
                      $currentComponent.style.setProperty('display', 'none', 'important');
                      flagComponentDragging($placeholderComponent);
                      flagComponentDragging($currentComponent);
                      // $currentComponent.classList.add('component-dragging');
                    }
                  }
                }
              }
            }

            appendFakeComponent();
            calcCursorFakeComponent(event, area);
            startAutoScroll(event);
            calcRectStickies();

            // Watch mousemove warper iframe. Fix for safari 17.2.1
            $iframe.removeEventListener('mousemove', listenMouseMoveDocumentIframe);
            $iframe.removeEventListener('mouseup', listenMouseUp);
            $iframe.addEventListener('mousemove', listenMouseMoveDocumentIframe);
            $iframe.addEventListener('mouseup', listenMouseUp);

            $iframeDoc.removeEventListener('mousemove', listenMouseMoveIframe);
            $iframeDoc.removeEventListener('mouseup', listenMouseUp);
            $iframeDoc.addEventListener('mousemove', listenMouseMoveIframe);
            $iframeDoc.addEventListener('mouseup', listenMouseUp);

            document.removeEventListener('mousemove', listenMouseMoveDocument);
            document.removeEventListener('mouseup', listenMouseUp);
            document.addEventListener('mousemove', listenMouseMoveDocument);
            document.addEventListener('mouseup', listenMouseUp);
            if (document.body) {
              document.body.style.cursor = 'grabbing';
              document.body.classList.add('select-none');
            }
            if ($iframeBody) {
              $iframeBody.style.cursor = 'grabbing';
              $iframeBody.classList.add('select-none');
            }
            editorStore.setDragging(true);
            editorStore.setEditingNumberClick(1);
          }
        }
      }
    }, delay);
  };

  const isTextOrHeadingFocus = (target: HTMLElement) => {
    const component = target.closest('[data-component-type="component"]');
    const tag = component?.getAttribute('data-component-tag');

    if (tag && TEXT_ELEMENTS.includes(tag)) {
      const textChildComponentUid = editorStore.getTextChildComponentUid;
      const query = textChildComponentUid ? `.gp-text[data-unique-text="${textChildComponentUid}"]` : '.gp-text';
      return component?.querySelector(query)?.getAttribute('contenteditable');
    }

    return false;
  };

  const isToolbar = (target?: HTMLElement) => {
    const isEl = target?.closest(
      '[data-spacing], [data-column], [data-outline], [data-toolbar-duplicate], [data-toolbar-delete], .theme-section-tooltip-wrapper, [data-toolbar-add-top], [data-toolbar-add-bottom], .gp-gen-content-toolbar-wrapper, .gp-switch-view',
    );
    if (isEl) return true;
    return false;
  };

  const getPreventSectionFrom = (target: HTMLElement) => {
    const component = target.closest('[data-component-type="component"]:not([data-component-no-drag-drop="true"]');
    const $section = component?.closest('[data-component-tag="Section"]');
    const $preventSection =
      $section?.getAttribute('data-theme-section') ?? $section?.getAttribute('data-shopify-section');

    return $preventSection && $section;
  };

  //==================== Mouse Move ==========================
  const listenMouseMoveDocument = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (target.closest(selector.iframe)) {
      return;
    }
    listenMouseMove(event, 'document');
  };

  const listenMouseMoveDocumentIframe = (event: MouseEvent) => {
    listenMouseMove(event, 'document-iframe');
  };

  const listenMouseMoveIframe = (event: MouseEvent) => {
    listenMouseMove(event, 'iframe');
  };

  const appendFakeComponent = () => {
    const $fakeComponent = document.body.querySelector<HTMLElement>(selector.fakeComponent);
    if ($fakeComponent) {
      $fakeComponent.remove();
    }

    const fakeDataComponent = {
      icon: `<svg width="24" height="24" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.4478 8C20.1931 8 19.979 8.19157 19.9509 8.44478L17.3953 31.4448C17.3624 31.741 17.5943 32 17.8923 32H19.5528C19.8076 32 20.0216 31.8084 20.0497 31.5552L22.6053 8.55521C22.6382 8.25903 22.4064 8 22.1084 8H20.4478ZM25.9539 11.6464C25.7586 11.8417 25.7586 12.1583 25.9539 12.3536L33.2468 19.6464C33.442 19.8417 33.442 20.1583 33.2468 20.3536L25.9539 27.6464C25.7586 27.8417 25.7586 28.1583 25.9539 28.3536L27.1134 29.5131C27.3087 29.7084 27.6253 29.7084 27.8205 29.5131L36.9801 20.3536C37.1754 20.1583 37.1754 19.8417 36.9801 19.6464L27.8205 10.4869C27.6253 10.2916 27.3087 10.2916 27.1134 10.4869L25.9539 11.6464ZM3.02055 19.6464C2.82528 19.8417 2.82528 20.1583 3.02055 20.3536L12.1801 29.5131C12.3754 29.7084 12.6919 29.7084 12.8872 29.5131L14.0468 28.3536C14.242 28.1583 14.242 27.8417 14.0468 27.6464L6.75388 20.3536C6.55862 20.1583 6.55862 19.8417 6.75388 19.6464L14.0468 12.3536C14.242 12.1583 14.242 11.8417 14.0468 11.6464L12.8872 10.4869C12.6919 10.2916 12.3754 10.2916 12.1801 10.4869L3.02055 19.6464Z"
            fill="currentColor" />
        </svg>
      `,
      label: '',
    };
    if (state.newPreset?.id) {
      const firstComponent = state.newPreset.components[0];
      const builderSetting = builderConfigStore.getBuilderSettingByTag(firstComponent.tag);
      fakeDataComponent.icon = builderSetting.icon || state.newPreset.icon.desktop;
      fakeDataComponent.label = state.newPreset.name.en;
    } else if (state.component?.tag) {
      const builderSettings = builderConfigStore.getBuilderSettings;
      const builderSetting = builderSettings[state.component.tag];
      if (builderSetting) {
        fakeDataComponent.icon = builderSetting.icon;
        fakeDataComponent.label = builderSetting.label;
      } else {
        sentryCaptureException('listenMouseMove', 'Can not find builder setting by tag', state.component, {
          level: 'error',
        });
      }
    }
    const htmlFakeComponent = `${fakeDataComponent.label}`;

    const $fake = document.createElement('div');
    $fake.innerHTML = htmlFakeComponent;
    $fake.setAttribute('id', selector.fakeComponent.replace(/#/g, ''));
    document.body.appendChild($fake);
  };

  const calcCursorFakeComponent = (event: MouseEvent, area: 'document' | 'iframe' | 'document-iframe') => {
    const $iframe = document.body.querySelector<HTMLIFrameElement>(selector.iframe);
    const $iframeScreens = document.body.querySelector<HTMLIFrameElement>(selector.iframeScreens);
    const $fakeComponent = document.body.querySelector<HTMLElement>(selector.fakeComponent);
    let top = event.clientY;
    let left = event.clientX;
    let topF = 0;
    let leftF = 0;

    // get position iframe
    if ($iframe && $iframeScreens) {
      const rect = $iframe?.getBoundingClientRect();
      topF = rect.top;
      leftF = rect.left;
      try {
        const transform = $iframeScreens.style.transform; // scale(0.920833, 0.934167)
        if (transform) {
          const transformScale = transform.replace('scale(', '').replace(')', ''); // 0.920833, 0.934167
          const scales = transformScale.split(',');
          if (scales.length == 2) {
            const scaleX = scales[0].trim(); // 0.920833
            const scaleY = scales[1].trim(); // 0.934167
            topF -= top * (1 - parseFloat(scaleY));
            leftF -= left * (1 - parseFloat(scaleX));
          }
        }
      } catch (e: any) {
        sentryCaptureException(
          'calcCursorFakeComponent',
          'Parsefloat error',
          {
            message: e?.message,
          },
          {
            level: 'error',
          },
        );
      }
    }

    // append position with drag & drop in iframe
    if (area === 'iframe') {
      top = top + topF;
      left = left + leftF;
    }

    // Set min view port
    if (top <= 0) top = 0;
    if (left <= 0) left = 0;

    if ($fakeComponent) {
      /** Style component fake */
      $fakeComponent.style.position = 'fixed';
      $fakeComponent.style.pointerEvents = 'none';
      $fakeComponent.style.background = 'rgba(37, 37, 37, 0.85)';
      $fakeComponent.style.color = '#FFFFFF';
      $fakeComponent.style.height = `39px`;
      $fakeComponent.style.padding = '0 16px';
      $fakeComponent.style.display = 'flex';
      $fakeComponent.style.justifyContent = 'center';
      $fakeComponent.style.alignItems = 'center';
      $fakeComponent.style.fontSize = '16px';
      $fakeComponent.style.fontWeight = '500';
      $fakeComponent.style.borderRadius = '8px';
      $fakeComponent.style.cursor = 'grabbing';

      $fakeComponent.style.top = `${top}px`;
      $fakeComponent.style.left = `${left}px`;
      $fakeComponent.style.zIndex = `9999999`;
      const $icon = $fakeComponent.querySelector('svg');
      if ($icon) {
        $icon.style.width = '32px';
        $icon.style.height = '32px';
        $icon.style.marginRight = '8px';
      }
      /** End style component fake */
    }
    return { top, left, topF, leftF };
  };

  const calcRectStickies = () => {
    const $iframe = document.body.querySelector<HTMLIFrameElement>(selector.iframe);
    if ($iframe) {
      const $iframeDoc = $iframe.contentDocument ?? ($iframe.contentWindow as any)?.document;
      const $stickies = $iframeDoc?.querySelectorAll(`[data-component-tag="${STICKY_TAG}"]`);
      if ($stickies?.length) {
        const rects = [];
        for (const $sticky of $stickies) {
          if ($sticky) {
            const rect = $sticky?.getBoundingClientRect();
            rects.push({
              top: rect.top,
              left: rect.left,
              width: rect.width,
              height: rect.height,
            });
          }
        }

        state.rectStickies = rects;
      }
    }
  };

  //==================== Calc Position ==========================
  const listenMouseMove = (event: MouseEvent, area: 'document' | 'iframe' | 'document-iframe') => {
    if (editorStore.getDragging) {
      /** Cursor */
      const { top, left, leftF, topF } = calcCursorFakeComponent(event, area);
      const isInIframe = (area === 'iframe' || area === 'document-iframe') && top >= 0 && left >= 0;
      /** Calc position drop, with debounce */
      if (state.timeoutDragging) clearTimeout(state.timeoutDragging);
      state.timeoutDragging = setTimeout(() => {
        const $iframe = document.body.querySelector<HTMLIFrameElement>(selector.iframe);
        if ($iframe) {
          updateClientY(event);
          if (!state.isAutoScroll) {
            startAutoScroll(event);
          }

          const $iframeDoc = $iframe.contentDocument ?? ($iframe.contentWindow as any).document;

          const scrollTop = $iframeDoc.documentElement.scrollTop;

          //check locked dropping area
          let needRerenderDropPlaceholders = true;
          if (isInIframe && state.rectDragging) {
            const cursorY = top - topF + scrollTop;
            const isExistSticky = state.rectDragging.cachedData.isExistSticky;
            const isInSection =
              cursorY >= state.rectDragging.top && cursorY <= state.rectDragging.top + state.rectDragging.height;
            const atTheTop = cursorY < state.rectDragging.top && state.rectDragging.cachedData.isDropOutbound === 'top';
            const atTheBottom =
              cursorY > state.rectDragging.top + state.rectDragging.height &&
              state.rectDragging.cachedData.isDropOutbound === 'bottom';
            const anywhere = state.rectDragging.cachedData.isDropOutbound === 'anywhere';
            const rectStickies = state.rectStickies;
            let isHoverInSticky = false;
            if (rectStickies?.length) {
              for (const rectSticky of rectStickies) {
                const newTop = topF + rectSticky.top;
                const newLeft = leftF + rectSticky.left;
                if (
                  newTop < top &&
                  top <= newTop + rectSticky.height &&
                  newLeft < left &&
                  left <= newLeft + rectSticky.width
                ) {
                  isHoverInSticky = true;
                  state.isHoverInSticky = true;
                  break;
                } else {
                  state.isHoverInSticky = false;
                }
              }
            } else {
              state.isHoverInSticky = false;
            }
            if ((isInSection && !isHoverInSticky) || ((atTheTop || atTheBottom || anywhere) && !isExistSticky)) {
              //in locked dropping area, prevent rerender dropping placeholders
              needRerenderDropPlaceholders = false;
            }
          }

          if (needRerenderDropPlaceholders) {
            //clear drop placeholders and active blocks
            state.rectDragging = null;

            const $oldVisualPlaceholders = $iframeDoc.querySelectorAll('.visual-placeholder');
            if ($oldVisualPlaceholders?.length) {
              for (const $oldVisualPlaceholder of $oldVisualPlaceholders) {
                $oldVisualPlaceholder.remove();
              }
            }

            const $oldFakePlaceholders = $iframeDoc.querySelectorAll('.fake-placeholder');
            if ($oldFakePlaceholders?.length) {
              for (const $oldFakePlaceholder of $oldFakePlaceholders) {
                $oldFakePlaceholder.remove();
              }
            }

            const $oldFakeComponents = $iframeDoc.querySelectorAll('.fake-visual-component');
            if ($oldFakeComponents?.length) {
              for (const $oldFakeComponent of $oldFakeComponents) {
                $oldFakeComponent.remove();
              }
            }
          } else {
            //reset drop placeholders
            const $activePlaceholders = $iframeDoc.querySelectorAll('.fake-placeholder.visual-placeholder');
            if ($activePlaceholders?.length) {
              for (const $activePlaceholder of $activePlaceholders) {
                $activePlaceholder.classList.remove('visual-placeholder');
                $activePlaceholder.classList.remove(`has-section`);
                $activePlaceholder.classList.remove(`has-theme-section`);
                $activePlaceholder.classList.remove(`visual-placeholder-between-sections`);
                $activePlaceholder.classList.remove(`visual-placeholder-child-on-sections`);
                $activePlaceholder.innerHTML = '';
                $activePlaceholder.classList.remove('visual-placeholder-error');
                $activePlaceholder.classList.remove('children-error');
                $activePlaceholder.classList.remove('limitation-error');
              }
            }
          }

          // Calc placeholder run only drag & drop in iframe
          if (isInIframe) {
            //calculate and rerender drop placeholders if necessary
            if (needRerenderDropPlaceholders) {
              const dragInfo = getDragInformation(state);
              const firstComponent = dragInfo?.components[0] as Component;

              // Find Sections
              const $modalAnchors = [];
              const $sectionAnchors = [];
              let isSectionAnchors = false; // flag check exits sections
              let isExistSticky = false;
              let $sectionBetweenAnchors = null;
              let sectionBetweenDirection: 'before' | 'after' | 'children' = 'after';
              let isDropOutbound: RectDraggingIsDropOutbound = '';

              const $rootAnchor = $iframeDoc.querySelector('#storefront [data-uid="ROOT"]');
              const $blankPagePlaceHolder = $iframeDoc.querySelector('.sf-add-section-placeholder');
              let isBlankPagePlaceHolder = false;
              let positionDropSticky: PositionDropSticky = 'top';

              // Find placeholder by modal
              if ($rootAnchor) {
                const $modalChilds = $rootAnchor.querySelectorAll(`dialog`);
                if ($modalChilds?.length) {
                  for (const $child of $modalChilds) {
                    if ($child.hasAttribute('open')) {
                      const $modalContent = $child.querySelector(`[data-component-tag="${MODAL_TAG}"]`);
                      if ($modalContent) {
                        $modalAnchors.push($modalContent);
                      }
                    }
                  }
                }
              }

              if ($rootAnchor && !$modalAnchors.length) {
                // Find placeholder by section
                const $childs = $rootAnchor.querySelectorAll(`[data-component-tag="${SECTION_TAG}"]`);
                if ($childs?.length) {
                  // const isRow = component?.tag == 'Row';
                  const dragInfo = getDragInformation(state);
                  const isSection = dragInfo?.components?.[0]?.tag == SECTION_TAG;
                  for (let c = 0; c < $childs.length; c++) {
                    const $child = $childs[c];
                    const $nextChild = $childs[c + 1];
                    const $previousChild = $childs[c - 1];
                    if ($child?.getAttribute('data-component-tag') == SECTION_TAG) {
                      if (
                        !isComponentVisible($child) &&
                        sectionStore.getItems.length === 1 &&
                        !isEditThemeSection.value
                      ) {
                        // if iframe has only one section and it's invisible then show a placeholder before a 'add section image to layout' block
                        const $addSectionBlock = $iframeDoc.querySelector(`#${ADD_SECTION_IMAGE_TO_LAYOUT_ID}`);
                        isSectionAnchors = true;
                        $sectionBetweenAnchors = $addSectionBlock;
                        sectionBetweenDirection = 'before';
                      } else {
                        let rect = $child?.getBoundingClientRect();
                        if (!isComponentVisible($child)) {
                          rect = $child.parentNode?.getBoundingClientRect();
                        }
                        const data = {
                          bottom: rect.bottom,
                          top: topF + rect.top,
                          left: leftF + rect.left,
                          width: rect.width,
                          height: rect.height,
                        };
                        const condition =
                          (top >= data.top + OFFSET && top <= data.top + data.height - OFFSET) || isSection;
                        // section
                        if (condition || isEditThemeSection.value) {
                          isSectionAnchors = true;
                          $sectionAnchors.push($child);
                        } else if (!$previousChild && top < data.top + OFFSET) {
                          // add new section on top
                          isSectionAnchors = true;
                          $sectionBetweenAnchors = $child;
                          sectionBetweenDirection = 'before';
                          isDropOutbound = 'top';
                        } else if (!$nextChild && top >= data.bottom - OFFSET) {
                          // add new section on bottom
                          isSectionAnchors = true;
                          $sectionBetweenAnchors = $child;
                          sectionBetweenDirection = 'after';
                          isDropOutbound = 'bottom';
                        }
                      }
                    }

                    if (
                      $child?.getAttribute('data-component-tag') == SECTION_TAG &&
                      $nextChild?.getAttribute('data-component-tag') == SECTION_TAG &&
                      !isSection
                    ) {
                      if (isComponentVisible($child) && isComponentVisible($nextChild)) {
                        const rect = $child?.getBoundingClientRect();
                        const dataCurrent = {
                          top: topF + rect.top,
                          left: leftF + rect.left,
                          width: rect.width,
                          height: rect.height,
                        };
                        const rectNext = $nextChild?.getBoundingClientRect();
                        const dataNext = {
                          top: topF + rectNext.top,
                          left: leftF + rectNext.left,
                          width: rectNext.width,
                          height: rectNext.height,
                        };
                        // between 2 section
                        if (top >= dataCurrent.top + dataCurrent.height - OFFSET && top <= dataNext.top + OFFSET) {
                          isSectionAnchors = true;
                          $sectionBetweenAnchors = $child;
                          break;
                        }
                      }
                    }
                  }
                }

                /**
                 * If iframe has no sections but there are still sections in store (Eg: When Popup element being closed)
                 * then show a placeholder before a 'add section image to layout' block.
                 */
                if (!$childs?.length && sectionStore.getItems.length > 0) {
                  const $addSectionBlock = $iframeDoc.querySelector(`#${ADD_SECTION_IMAGE_TO_LAYOUT_ID}`);
                  isSectionAnchors = true;
                  $sectionBetweenAnchors = $addSectionBlock;
                  sectionBetweenDirection = 'before';
                  isDropOutbound = 'anywhere';
                }

                // Find placeholder by sticky
                const $stickies = $rootAnchor.querySelectorAll(`[data-component-tag="${STICKY_TAG}"]`);
                if ($stickies?.length) {
                  for (const $child of $stickies) {
                    if ($child?.getAttribute('data-component-tag') == STICKY_TAG) {
                      if (isComponentVisible($child)) {
                        const rect = $child?.getBoundingClientRect();
                        const data = {
                          bottom: rect.bottom,
                          top: topF + rect.top,
                          left: leftF + rect.left,
                          width: rect.width,
                          height: rect.height,
                        };
                        const condition = top >= data.top && top <= data.top + data.height;
                        isExistSticky = true;
                        if (condition) {
                          $sectionAnchors.push($child);
                        }
                      }
                    }
                  }
                }
              }

              // Find placeholders
              let placeholders: PlaceHolder[] = [];
              const hasStickyTag = firstComponent?.tag == STICKY_TAG || isDragStickySection.value;
              if (hasStickyTag) {
                const rect = $iframe?.getBoundingClientRect();
                if (top >= rect.height / 2) {
                  positionDropSticky = 'bottom';
                }
                let $stickyOverlay = $iframeDoc.querySelector('.sticky-overlay');
                if (!$stickyOverlay) {
                  $stickyOverlay = $iframe?.contentDocument?.createElement('div');
                  $stickyOverlay.classList.add('sticky-overlay');
                  if (sectionStore.getItems.length === 0) {
                    $stickyOverlay.innerHTML = dropElementHereHtml();
                    $stickyOverlay.classList.add('is-blank-page');
                  } else {
                    $stickyOverlay.innerHTML = '';
                  }
                  $iframeDoc.body.appendChild($stickyOverlay);
                }
                if (positionDropSticky == 'top') {
                  $stickyOverlay.classList.remove('highlight-bottom');
                  $stickyOverlay.classList.add('highlight-top');
                  $stickyOverlay.setAttribute('data-position-drag-sticky', 'top');
                } else {
                  $stickyOverlay.classList.remove('highlight-top');
                  $stickyOverlay.classList.add('highlight-bottom');
                  $stickyOverlay.setAttribute('data-position-drag-sticky', 'bottom');
                }
                $stickyOverlay.style.cursor = 'grabbing';
              } else if ($modalAnchors?.length) {
                for (const $modalAnchor of $modalAnchors) {
                  const newPlaceholders = findPlaceholderComponents($modalAnchor, scrollTop, dragInfo?.components);
                  if (newPlaceholders) {
                    if (newPlaceholders.length) {
                      placeholders = placeholders.concat(newPlaceholders);
                    }
                    //lock dropping area
                    state.rectDragging = {
                      top: 0,
                      height: $iframeDoc.querySelector('html')?.scrollHeight || 0,
                      cachedData: {
                        modalAnchorsLength: $modalAnchors.length,
                        sectionAnchorsLength: $sectionAnchors.length,
                        isSectionAnchors,
                        isSectionBetweenAnchors: !!$sectionBetweenAnchors,
                        isDropOutbound,
                        isExistSticky,
                      },
                    };
                  }
                }
              } else if ($sectionAnchors?.length) {
                // const isRow = component?.tag == 'Row';
                const isSection = firstComponent?.tag == SECTION_TAG;
                const isAllowDragSectionIntoSection =
                  editorStore.getBuildWithSectionMode && isEditThemeSection.value && sectionStore.isThemeSectionEmpty;

                // if ((isRow && isNew) || isSection) {
                if (isSection && !isAllowDragSectionIntoSection) {
                  for (const $sectionAnchor of $sectionAnchors) {
                    if ($sectionAnchor) {
                      const dataId = $sectionAnchor.getAttribute('data-uid');
                      const rect = $sectionAnchor?.getBoundingClientRect();
                      placeholders.push({
                        uid: dataId,
                        appendChild: false,
                        appendBeforeAfter: true,
                        appendLeftRight: false,
                        top: rect.top + scrollTop,
                        left: rect.left,
                        width: rect.width,
                        height: rect.height,
                        tag: rect.tag,
                      });
                    }
                  }
                  //lock dropping area
                  state.rectDragging = {
                    top: 0,
                    height: $iframeDoc.querySelector('html')?.scrollHeight || 0,
                    cachedData: {
                      modalAnchorsLength: $modalAnchors.length,
                      sectionAnchorsLength: $sectionAnchors.length,
                      isSectionAnchors,
                      isSectionBetweenAnchors: !!$sectionBetweenAnchors,
                      isDropOutbound,
                      isExistSticky,
                    },
                  };
                }

                if (!isSection || isAllowDragSectionIntoSection) {
                  for (const $sectionAnchor of $sectionAnchors) {
                    const newPlaceholders = findPlaceholderComponents($sectionAnchor, scrollTop, dragInfo?.components);
                    if (newPlaceholders) {
                      if (newPlaceholders.length) {
                        placeholders = placeholders.concat(newPlaceholders);
                      }
                      //lock dropping area
                      const rect = $sectionAnchor?.getBoundingClientRect();
                      state.rectDragging = {
                        top: rect.top + scrollTop + OFFSET,
                        height: rect.height - OFFSET,
                        cachedData: {
                          modalAnchorsLength: $modalAnchors.length,
                          sectionAnchorsLength: $sectionAnchors.length,
                          isSectionAnchors,
                          isSectionBetweenAnchors: !!$sectionBetweenAnchors,
                          isDropOutbound,
                          isExistSticky,
                        },
                      };
                    }
                  }
                }
              } else if ($sectionBetweenAnchors) {
                let rect = $sectionBetweenAnchors?.getBoundingClientRect();
                if (!isComponentVisible($sectionBetweenAnchors)) {
                  rect = $sectionBetweenAnchors.parentNode?.getBoundingClientRect();
                }
                const dataCurrent = {
                  top: rect.top,
                  left: rect.left,
                  width: rect.width,
                  height: rect.height,
                };
                const dataId = $sectionBetweenAnchors.getAttribute('data-uid');
                const sectionCid = cacheGetSectionCidByComponentUid(dataId);
                const $iframe = document.body.querySelector<HTMLIFrameElement>(selector.iframe);

                placeholders.push({
                  uid: 'ROOT',
                  appendChild: true,
                  appendBeforeAfter: false,
                  appendLeftRight: false,
                  top:
                    sectionBetweenDirection === 'after'
                      ? dataCurrent.height + dataCurrent.top + scrollTop - 3
                      : dataCurrent.top,
                  left: 0,
                  width: $iframe?.width || dataCurrent.width,
                  height: 6,
                  afterId: sectionCid,
                  direction:
                    isDropOutbound == 'anywhere' && sectionStore.items.length == sectionStore.stickies.length
                      ? 'after'
                      : sectionBetweenDirection,
                  tag: rect.tag,
                });

                //lock dropping area
                state.rectDragging = {
                  top:
                    (sectionBetweenDirection === 'after'
                      ? dataCurrent.height + dataCurrent.top + scrollTop - 3
                      : dataCurrent.top) - OFFSET,
                  height: 6 + OFFSET + 3,
                  cachedData: {
                    modalAnchorsLength: $modalAnchors.length,
                    sectionAnchorsLength: $sectionAnchors.length,
                    isSectionAnchors,
                    isSectionBetweenAnchors: !!$sectionBetweenAnchors,
                    isDropOutbound,
                    isExistSticky,
                  },
                };
              } else if ($rootAnchor && sectionStore.getItems.length === 0) {
                // Auto add default placeholder
                const headerHeight = isPostPurchasePage.value ? 92 : 40;
                if ($blankPagePlaceHolder) {
                  const blankPagePlaceHolder = $blankPagePlaceHolder?.getBoundingClientRect();
                  const rootWidth = $iframe.contentWindow?.innerWidth || blankPagePlaceHolder.width;
                  const hasHeader =
                    $iframeDoc.querySelector('.gp-header-container') &&
                    !$iframeDoc.querySelector('.gp-header-container').classList.contains('hidden');
                  isBlankPagePlaceHolder = true;
                  placeholders.push({
                    uid: 'ROOT',
                    appendChild: true,
                    appendBeforeAfter: false,
                    appendLeftRight: false,
                    top: hasHeader ? headerHeight - 16 : -16,
                    left: -16,
                    width: rootWidth,
                    height: limitSection.value ? 64 : blankPagePlaceHolder.height + 2,
                    tag: 'ROOT',
                  });

                  //lock dropping area
                  state.rectDragging = {
                    top: 0,
                    height: $iframeDoc.querySelector('html')?.scrollHeight || 0,
                    cachedData: {
                      modalAnchorsLength: $modalAnchors.length,
                      sectionAnchorsLength: $sectionAnchors.length,
                      isSectionAnchors,
                      isSectionBetweenAnchors: !!$sectionBetweenAnchors,
                      isDropOutbound,
                      isExistSticky,
                    },
                  };
                }
              }

              // Create visual drag & drop
              const $visualContent = $iframeDoc.querySelector('#visual-content');
              if ($visualContent) {
                $visualContent.style.display = 'block';
                $visualContent.style.cursor = 'grabbing';
              }

              const mapPositionRects: Record<string, rectPositionPlaceHolder> = {};
              const { nextDataUid, prevDataUid, childrenDataUids } = getComponentRelations(
                $iframeDoc,
                firstComponent?.uid,
              );
              for (const placeholder of placeholders) {
                // If dragging, do not initialize placeholders for child items.
                if (childrenDataUids.includes(placeholder.uid)) {
                  continue;
                }

                const dataId = placeholder.uid;
                const dataAfterId = placeholder.afterId;
                const appendChild = placeholder.appendChild;
                // Calc placeholder
                let placeHeight = 6;
                let cTop = placeholder.top - placeHeight / 2;
                const cLeft = placeholder.left;
                const cHeight = placeholder.height;
                const cWidth = placeholder.width;
                const productId = placeholder.productId ?? '';
                const articleId = placeholder.articleId ?? '';
                const parentTag = placeholder.parentTag ?? '';
                const marqueeItemKey = placeholder.marqueeItemKey ?? '';
                const tag = placeholder.tag ?? '';

                let rects: rectPositionPlaceHolder[] = [];

                if (appendChild) {
                  placeHeight = placeholder.height;
                  cTop = placeholder.top + parseFloat(placeholder.margin?.top ?? '0px');
                  rects = [
                    {
                      direction: placeholder.direction ?? 'children',
                      pTop: cTop,
                      pLeft: cLeft,
                      pWidth: cWidth,
                      pHeight: cHeight,
                      placeholderHeight: placeHeight,
                      cWidth: cWidth,
                      cHeight: cHeight,
                      isSlot: placeholder.isSlot,
                      isThemeSection: placeholder.isThemeSection,
                      isExceededSection: placeholder.isExceededSection,
                      isShopifySection: placeholder.isShopifySection,
                      productId,
                      articleId,
                      parentTag,
                      tag,
                      marqueeItemKey,
                    },
                  ];
                } else if (dataId == firstComponent.uid) {
                  placeHeight = placeholder.height;
                  cTop = placeholder.top + parseFloat(placeholder.margin?.top ?? '0px');
                  rects = [
                    {
                      direction: 'before',
                      pTop: cTop,
                      pLeft: cLeft,
                      pWidth: cWidth,
                      pHeight: cHeight,
                      placeholderHeight: placeHeight,
                      cWidth: cWidth,
                      cHeight: cHeight,
                      isComponentDragging: true,
                      productId,
                      articleId,
                      parentTag,
                      tag,
                      marqueeItemKey,
                    },
                  ];
                } else {
                  let beforeRect: rectPositionPlaceHolder | undefined;
                  let afterRect: rectPositionPlaceHolder | undefined;
                  if (placeholder.appendBeforeAfter) {
                    beforeRect = {
                      direction: 'before',
                      pTop: Math.max(cTop, 0),
                      pLeft: cLeft,
                      pWidth: cWidth,
                      pHeight: cHeight,
                      placeholderHeight: placeHeight,
                      cWidth: cWidth,
                      cHeight: cHeight,
                      productId,
                      articleId,
                      parentTag,
                      tag,
                      marqueeItemKey,
                    };

                    afterRect = {
                      direction: 'after',
                      pTop: cTop + cHeight + parseFloat(placeholder.margin?.bottom || '0px'),
                      pLeft: cLeft,
                      pWidth: cWidth,
                      pHeight: cHeight,
                      placeholderHeight: placeHeight,
                      cWidth: cWidth,
                      cHeight: cHeight,
                      productId,
                      articleId,
                      parentTag,
                      tag,
                      marqueeItemKey,
                    };
                  }

                  let leftRect: rectPositionPlaceHolder | undefined;
                  let rightRect: rectPositionPlaceHolder | undefined;
                  if (placeholder.appendLeftRight) {
                    leftRect = {
                      direction: 'left',
                      pTop: cTop + 3,
                      pLeft: cLeft,
                      pWidth: 6,
                      pHeight: cHeight,
                      placeholderHeight: cHeight,
                      cWidth: cWidth,
                      cHeight: cHeight,
                      productId,
                      articleId,
                      parentTag,
                      tag,
                      marqueeItemKey,
                    };

                    rightRect = {
                      direction: 'right',
                      pTop: cTop + 3,
                      pLeft: cLeft + cWidth - 6,
                      pWidth: 6,
                      pHeight: cHeight,
                      placeholderHeight: cHeight,
                      cWidth: cWidth,
                      cHeight: cHeight,
                      productId,
                      articleId,
                      parentTag,
                      tag,
                      marqueeItemKey,
                    };
                  }

                  if (nextDataUid === dataId) {
                    afterRect && rects.push(afterRect);
                    if (leftRect && rightRect) {
                      rects = rects.concat([leftRect, rightRect]);
                    }
                  } else if (prevDataUid === dataId) {
                    beforeRect && rects.push(beforeRect);
                    if (leftRect && rightRect) {
                      rects = rects.concat([leftRect, rightRect]);
                    }
                  } else {
                    if (beforeRect && afterRect && leftRect && rightRect) {
                      rects = [beforeRect, afterRect, leftRect, rightRect];
                    } else if (beforeRect && afterRect) {
                      rects = [beforeRect, afterRect];
                    } else if (leftRect && rightRect) {
                      rects = [leftRect, rightRect];
                    }
                  }
                }
                // Create visual placeholder

                rects.forEach(
                  ({
                    direction,
                    pTop,
                    pLeft,
                    pWidth,
                    placeholderHeight,
                    cWidth,
                    cHeight,
                    isSlot,
                    isComponentDragging,
                    productId,
                    articleId,
                    tag,
                    isThemeSection,
                    isExceededSection,
                    isShopifySection,
                    marqueeItemKey,
                  }) => {
                    const keys = Object.keys(mapPositionRects);
                    const key = `${direction}-${pTop}-${pLeft}`;
                    const exist = keys.find((k) => k.includes(key));
                    // đối với marquee do tính chất lặp lại của item do đó sẽ cần bỏ qua các trường hợp item phía sau bị trùng vị trí với item trước
                    if (exist && tag === 'MarqueeItem') {
                      return;
                    }
                    mapPositionRects[
                      `${direction}-${pTop}-${pLeft}-${pWidth}-${placeholderHeight}-${cWidth}-${cHeight}`
                    ] = {
                      direction,
                      pTop,
                      pLeft,
                      pWidth,
                      placeholderHeight,
                      cWidth: cWidth,
                      cHeight: cHeight,
                      isSlot,
                      isComponentDragging,
                      dataId,
                      dataAfterId,
                      appendChild,
                      productId,
                      articleId,
                      parentTag,
                      tag,
                      isThemeSection,
                      isExceededSection,
                      isShopifySection,
                      marqueeItemKey,
                    };
                  },
                );
              }

              // create dom
              Object.values(mapPositionRects).forEach((rect) => {
                const {
                  direction,
                  pTop,
                  pLeft,
                  pWidth,
                  placeholderHeight,
                  cWidth,
                  cHeight,
                  isSlot,
                  isComponentDragging,
                  dataId,
                  dataAfterId,
                  appendChild,
                  productId,
                  articleId,
                  parentTag,
                  tag,
                  isThemeSection,
                  isExceededSection,
                  isShopifySection,
                  marqueeItemKey,
                } = rect;
                const $fakePlaceholder = $iframeDoc.createElement('div');

                // Set attrs
                // $fakePlaceholder.classList.add('not-show');
                $fakePlaceholder.setAttribute('class', `fake-placeholder`);
                $fakePlaceholder.setAttribute('data-uid', dataId);
                $fakePlaceholder.setAttribute('data-direction', direction);
                $fakePlaceholder.setAttribute('data-after-uid', dataAfterId || '');
                $fakePlaceholder.setAttribute('data-child-top', pTop);
                $fakePlaceholder.setAttribute('data-product-id', productId);
                $fakePlaceholder.setAttribute('data-article-id', articleId);
                $fakePlaceholder.setAttribute('data-parent-tag', parentTag);
                $fakePlaceholder.setAttribute('data-tag', tag);
                $fakePlaceholder.setAttribute('data-append-child', appendChild);
                $fakePlaceholder.setAttribute('is-slot', isSlot || '');
                $fakePlaceholder.setAttribute('is-component-dragging', isComponentDragging || '');
                $fakePlaceholder.setAttribute('data-component-width', cWidth);
                $fakePlaceholder.setAttribute('data-component-height', cHeight);
                $fakePlaceholder.setAttribute('marquee-item-key', marqueeItemKey || '');
                if (isThemeSection) {
                  $fakePlaceholder.setAttribute('is-drag-to-theme-section', 'true');
                }
                if (isExceededSection) {
                  $fakePlaceholder.setAttribute('is-drag-to-exceeded-section', 'true');
                }

                if (isShopifySection) {
                  $fakePlaceholder.setAttribute('is-drag-to-shopify-section', 'true');
                }

                if (isBlankPagePlaceHolder) {
                  $fakePlaceholder.setAttribute('is-blank-page', 'true');
                }
                if (appendChild) {
                  $fakePlaceholder.classList.add('fake-placeholder-slot');
                }

                // Styling
                $fakePlaceholder.style.top = `${pTop}px`;
                $fakePlaceholder.style.left = `${pLeft}px`;
                $fakePlaceholder.style.width = `${pWidth}px`;
                $fakePlaceholder.style.height = `${placeholderHeight}px`;

                // Append DOM
                $visualContent.appendChild($fakePlaceholder);
              });
            }

            // Find near X,Y
            const $placeholders = $iframeDoc.querySelectorAll('.fake-placeholder');
            if ($placeholders?.length) {
              // Find near elements
              const mapElements: Record<string, HTMLElement[]> = {};
              let focusUid = '';
              let minDistantWidthHeight = 999999999;
              for (const $placeholder of $placeholders) {
                if (!$placeholder) continue;
                const rect = $placeholder?.getBoundingClientRect();
                const newTop = topF + rect.top;
                const newLeft = leftF + rect.left;
                const uid = $placeholder.getAttribute('data-uid');
                const direction = $placeholder.getAttribute('data-direction');
                const cWidth = parseFloat($placeholder.getAttribute('data-component-width') ?? '0') ?? 0;
                const cHeight = parseFloat($placeholder.getAttribute('data-component-height') ?? '0') ?? 0;
                const distant = Math.sqrt(cWidth ** 2 + cHeight ** 2);
                mapElements[uid] = mapElements[uid] ?? [];
                mapElements[uid].push($placeholder);

                if (direction == 'before' || direction == 'children' || direction == 'left') {
                  const overWidth = left >= newLeft && left <= newLeft + cWidth;
                  const overHeight = top >= newTop && top <= newTop + cHeight;
                  if (overWidth && overHeight) {
                    if (minDistantWidthHeight >= distant) {
                      minDistantWidthHeight = distant;
                      focusUid = uid;
                    }
                  }
                } else if (direction == 'after') {
                  const overWidth = left >= newLeft && left <= newLeft + cWidth;
                  const overHeight = top <= newTop && top >= newTop - cHeight;
                  if (overWidth && overHeight) {
                    if (minDistantWidthHeight >= distant) {
                      minDistantWidthHeight = distant;
                      focusUid = uid;
                    }
                  }
                } else if (direction == 'right') {
                  const overWidth = left <= newLeft && left >= newLeft - cWidth;
                  const overHeight = top >= newTop && top <= newTop + cHeight;
                  if (overWidth && overHeight) {
                    if (minDistantWidthHeight >= distant) {
                      minDistantWidthHeight = distant;
                      focusUid = uid;
                    }
                  }
                }
              }
              let nearElements = [];
              if (focusUid) {
                nearElements = mapElements[focusUid] ?? [];
              } else {
                nearElements = $placeholders;
              }

              const firstPlaceholder = mapElements[focusUid]?.[0];
              if (firstPlaceholder && firstPlaceholder?.getAttribute('data-tag') === 'Col') {
                // query col children
                const col = $iframeDoc.querySelector(`[data-uid="${focusUid}"]`);
                const childs = col.children;
                for (const child of childs) {
                  const uid = child.getAttribute('data-uid');
                  if (!uid) continue;
                  const childPlaceholders = mapElements[uid] ?? [];
                  if (childPlaceholders.length > 0) {
                    nearElements = nearElements.concat(childPlaceholders);
                  }
                }
              }

              // Find near X
              const nearX = [];
              for (const $placeholder of nearElements) {
                const rect = $placeholder?.getBoundingClientRect();
                const direction = $placeholder.getAttribute('data-direction');

                // disable focus placeholder left right column
                const tag = $placeholder?.getAttribute('data-tag');
                if (tag === 'Col' && direction !== 'children') continue;

                const cWidth = parseFloat($placeholder.getAttribute('data-component-width') ?? '0') ?? 0;
                const x1 = leftF + rect.left;
                const x2 = leftF + rect.left + rect.width;
                if (left >= x1 && left <= x2) {
                  nearX.push($placeholder);
                } else if (direction == 'left') {
                  if (left >= x1 && left <= x2 + cWidth * 0.1) {
                    nearX.push($placeholder);
                  }
                } else if (direction == 'right') {
                  if (left >= x1 - cWidth * 0.1 && left <= x2) {
                    nearX.push($placeholder);
                  }
                }
              }

              if (nearX.length == 0) {
                if ($placeholders.length == 1) {
                  nearX.push($placeholders[0]);
                } else {
                  let $target = null;
                  let minDistant = 9999999999999;
                  let maxheight = 0;
                  for (const $placeholder of $placeholders) {
                    const direction = $placeholder.getAttribute('data-direction');
                    // disable focus placeholder left right column
                    const tag = $placeholder?.getAttribute('data-tag');
                    if (tag === 'Col' && direction !== 'children') continue;

                    const pRect = $placeholder?.getBoundingClientRect();
                    const newLeft = leftF + pRect.left;
                    const minLeftRight = Math.min(Math.abs(newLeft - left), Math.abs(newLeft + pRect.width - left));
                    const pHeight = pRect.height;
                    if (minLeftRight < minDistant || (minLeftRight === minDistant && pHeight >= maxheight)) {
                      maxheight = pHeight;
                      minDistant = minLeftRight;
                      $target = $placeholder;
                    }
                  }
                  nearX.push($target);
                }
              }

              // Find near Y
              let $nearPlaceholder;
              let minDistant = 9999999999999;
              if (nearX?.length) {
                for (const $placeholder of nearX) {
                  const rect = $placeholder?.getBoundingClientRect();

                  const newTop = topF + rect.top;
                  const y1 = newTop + rect.height / 2;

                  const distantY = Math.abs(top - y1);
                  const childTop = $placeholder.getAttribute('data-child-top');
                  const direction = $placeholder.getAttribute('data-direction');
                  const isSlot = $placeholder.getAttribute('is-slot');
                  const parentTag = $placeholder.getAttribute('data-parent-tag');
                  if (parentTag != STICKY_TAG && state.isHoverInSticky) {
                    continue;
                  }

                  const isDragHoz = direction == 'left' || direction == 'right';
                  if (isDragHoz && top > newTop && top < newTop + rect.height && minDistant) {
                    minDistant = 0;
                    $nearPlaceholder = $placeholder;
                  } else if (isSlot && top > newTop && top < newTop + rect.height) {
                    minDistant = 0;
                    $nearPlaceholder = $placeholder;
                  } else if (minDistant > distantY) {
                    minDistant = distantY;
                    $nearPlaceholder = $placeholder;
                  } else if (minDistant == distantY) {
                    if (
                      (direction === 'before' && childTop < top + scrollTop) ||
                      (direction === 'after' && childTop > top + scrollTop) ||
                      direction === 'children'
                    ) {
                      minDistant = distantY;
                      $nearPlaceholder = $placeholder;
                    }
                  }
                }
              }

              if ($nearPlaceholder) {
                const $storefront = $iframeDoc.querySelector(`#storefront`);
                const dataDirection = $nearPlaceholder.getAttribute('data-direction');
                // Add visual for placeholder active
                $nearPlaceholder.classList.add(`visual-placeholder`);
                if (isEditThemeSection.value) $nearPlaceholder.classList.add(`visual-placeholder-theme-section`);
                if (
                  (state.rectDragging?.cachedData.modalAnchorsLength ||
                    state.rectDragging?.cachedData.sectionAnchorsLength) &&
                  dataDirection !== 'children'
                ) {
                  $nearPlaceholder.classList.add(`has-section`);
                }

                if (state.rectDragging?.cachedData.isSectionBetweenAnchors && !state.isHoverInSticky) {
                  $nearPlaceholder.classList.add(`visual-placeholder-between-sections`);
                }

                const $section = $storefront.querySelector('section');
                const hasNoSection = !$section && sectionStore.getItems.length === 0;
                if (hasNoSection) {
                  $nearPlaceholder.classList.add(`visual-placeholder-child-on-sections`);
                  $nearPlaceholder.innerHTML = dropElementHereHtml();
                }

                // Handle flow tag - Ex: Product title drag & drop only to Product
                if ($nearPlaceholder) {
                  const dragInfo = getDragInformation(state);
                  const firstComponent = dragInfo?.components?.[0];
                  const componentUid = $nearPlaceholder.getAttribute('data-uid');
                  const $nearComponents = $storefront.querySelectorAll(`[data-uid="${componentUid}"]`);
                  const dataAfterId = $nearPlaceholder.getAttribute('data-after-uid');
                  const productId = $nearPlaceholder.getAttribute('data-product-id') ?? '';
                  const articleId = $nearPlaceholder.getAttribute('data-article-id') ?? '';
                  const buildWithSection = dragInfo?.isNew && firstComponent?.tag === SECTION_TAG;

                  // Show hover parent
                  if ($nearComponents?.length) {
                    for (const $element of $nearComponents) {
                      const isDragToThemeSection =
                        $element.getAttribute('data-theme-section') &&
                        $nearPlaceholder.getAttribute('is-drag-to-theme-section') == 'true';

                      const isDragToShopifySection =
                        $element.getAttribute('data-shopify-section') &&
                        $nearPlaceholder.getAttribute('is-drag-to-shopify-section') == 'true';

                      const limitSections = 25;
                      const sectionCid = cacheGetSectionCidByComponentUid(componentUid);
                      const sectionIndex = sectionStore.getItems.findIndex((section) => section.cid === sectionCid);
                      const isExceedLimit = sectionIndex >= limitSections;

                      if (isDragToThemeSection || isDragToShopifySection) {
                        $nearPlaceholder.innerHTML = `
                          <div class="drag-to-section-message ${isExceedLimit ? 'exceed-limit' : ''}">
                            <div class="drag-to-section-message-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM8 10.5C7.58579 10.5 7.25 10.8358 7.25 11.25C7.25 11.6642 7.58579 12 8 12C8.41421 12 8.75 11.6642 8.75 11.25C8.75 10.8358 8.41421 10.5 8 10.5ZM8.5 4C8.5 3.72386 8.27614 3.5 8 3.5C7.72386 3.5 7.5 3.72386 7.5 4V9C7.5 9.27614 7.72386 9.5 8 9.5C8.27614 9.5 8.5 9.27614 8.5 9V4Z" fill="white"/>
                              </svg>
                            </div>
                            <p>Elements can't be dragged into ${
                              isDragToThemeSection ? 'Theme Section' : 'Shopify section'
                            }</p>
                          </div>
                        `;
                        return;
                      }

                      const currentProductId =
                        $element.closest('[data-component-tag="Product"]')?.getAttribute('data-product-id') ?? '';
                      const currentArticleId =
                        $element.closest('[data-component-tag="Article"]')?.getAttribute('data-article-id') ?? '';
                      if (currentProductId) {
                        editorStore.setEditingProductId(productId);
                        const $product = $element.closest('[data-component-tag="Product"]');
                        const $productList = $product.closest('[data-component-tag="ProductList"]');
                        let wrapProductUid = $product?.getAttribute('data-uid');
                        if ($productList) {
                          wrapProductUid = $productList?.getAttribute('data-uid');
                        }
                        if (!wrapProductUid) return;
                        editorStore.setEditingWrapProductUid(wrapProductUid);
                      }

                      if (currentProductId == productId || currentArticleId == articleId) {
                        setHoverParentElement($element);
                      }
                    }
                  }

                  // Limit section > 25 sections
                  if (limitSection.value && componentUid === 'ROOT' && !buildWithSection) {
                    $nearPlaceholder.classList.add('visual-placeholder-error');

                    if (!state.rectDragging?.cachedData.isSectionAnchors || dataDirection === 'children') {
                      $nearPlaceholder.innerHTML =
                        '<span>Page has reached Shopify’s 25 section-limit.<br/> Please combine or remove existing sections to add a new one.</span>';
                    } else {
                      $nearPlaceholder.innerHTML = '';
                      $nearPlaceholder.classList.add('children-error');
                    }

                    if (dataAfterId) {
                      $nearPlaceholder.classList.add('limitation-error');
                      $nearPlaceholder.innerHTML = renderLimitLabel();
                    }
                  }

                  // Drag new component
                  if (firstComponent) {
                    const { flowTags } = getAllChildrensFlowTags(dragInfo.components as Component[]);
                    if (flowTags.length) {
                      if ($nearComponents?.length) {
                        const $nearComponent = $nearComponents[0];
                        let $flowParent;
                        // Find parent
                        for (let i = 0; i < flowTags.length; i++) {
                          const flowTag = flowTags[i];
                          $flowParent = $nearComponent.closest(
                            `[data-component-tag="${flowTag}"]:not([data-uid="${componentUid}"])`,
                          );
                          if ($flowParent) break;
                        }

                        if (!$flowParent) {
                          $nearPlaceholder.classList.add('visual-placeholder-error');
                          if (!state.rectDragging?.cachedData.isSectionAnchors || dataDirection === 'children') {
                            $nearPlaceholder.innerHTML = 'Can not drop element here';
                          } else {
                            $nearPlaceholder.innerHTML = '';
                            $nearPlaceholder.classList.add('children-error');
                          }
                        }
                      }
                    }
                  }

                  // Drag new component for Page
                  if (firstComponent) {
                    const flowPages = getFlowPages(dragInfo.components);
                    if (flowPages.length) {
                      if (!flowPages.includes(editingPageType.value || '')) {
                        $nearPlaceholder.classList.add('visual-placeholder-error');
                        if (!state.rectDragging?.cachedData.isSectionAnchors || dataDirection === 'children') {
                          $nearPlaceholder.innerHTML = 'Can not drop element here';
                        } else {
                          $nearPlaceholder.innerHTML = '';
                          $nearPlaceholder.classList.add('children-error');
                        }
                      }
                    }
                  }

                  // not append into tags
                  if (firstComponent) {
                    const { notAppendTags } = getNotAppendTags(dragInfo.components);
                    if (notAppendTags.length) {
                      if ($nearComponents?.length) {
                        const $nearComponent = $nearComponents[0];
                        let $notAppendParent;
                        // Find parent
                        for (const tag of notAppendTags) {
                          $notAppendParent = $nearComponent.closest(
                            `[data-component-tag="${tag}"]:not([data-uid="${componentUid}"])`,
                          );
                          if ($notAppendParent) break;
                        }

                        if ($notAppendParent) {
                          $nearPlaceholder.classList.add('visual-placeholder-error');
                          const parentTag = $notAppendParent.getAttribute('data-component-label');
                          $nearPlaceholder.setAttribute('data-not-append-parent-tag', parentTag);
                          if (!state.rectDragging?.cachedData.isSectionAnchors || dataDirection === 'children') {
                            $nearPlaceholder.innerHTML = 'Can not drop element here';
                          } else {
                            $nearPlaceholder.innerHTML = '';
                            $nearPlaceholder.classList.add('children-error');
                          }
                        }
                      }
                    }
                  }

                  // #region prevent append to target element
                  if (
                    $nearPlaceholder?.getAttribute('data-direction') === 'children' &&
                    firstComponent &&
                    $nearComponents.length &&
                    isPreventAppend(firstComponent.tag, $nearComponents[0])
                  ) {
                    $nearPlaceholder.classList.add('visual-placeholder-error');
                    $nearPlaceholder.innerHTML = 'Can not drop element here';
                  }
                  //#endregion

                  const dataTag = $nearPlaceholder.getAttribute('data-tag');
                  if (dataDirection === 'left' || dataDirection === 'right') {
                    const $nearComponent = $nearComponents[0];
                    let $col = $nearComponent;
                    if (dataTag !== 'Col') {
                      $col = $nearComponent.parentNode;
                    }
                    if ($col.getAttribute('data-component-tag') === 'Col') {
                      const $childsOfCol = $col.querySelectorAll(':scope > [data-component-tag]');
                      const layoutList = ['Row', 'Section', 'Product'];
                      const $rowOfCol = $col.parentNode;
                      const parentTag = $rowOfCol.getAttribute('data-component-tag');
                      if (layoutList.includes(parentTag)) {
                        const $childsOfRow = $rowOfCol.querySelectorAll(':scope > [data-component-tag]');
                        const isMaxColumns =
                          (dataTag == 'Col' && $childsOfRow.length >= MAX_COLUMNS) ||
                          ($childsOfCol.length === 1 && $childsOfRow.length >= MAX_COLUMNS);
                        if (isMaxColumns) {
                          $nearPlaceholder.classList.add('visual-placeholder-error');
                          $nearPlaceholder.classList.add('children-error');
                          $nearPlaceholder.classList.add('max-columns');
                        }
                      }
                    }
                  }
                }
              }
            }
          } else {
            setHoverParentElement(undefined);
            $iframeDoc.querySelector('.sticky-overlay')?.remove();
          }
        }
      }, 10);
      /** END calc position drop */
    }
  };

  function getComponentRelations($iframeDoc: HTMLElement, firstComponentUid: string) {
    const $currentElement = $iframeDoc.querySelector(
      `#storefront [data-uid="${firstComponentUid}"]:not(.placeholder-component-dragging)`,
    );
    const $currentElementDragging = $iframeDoc.querySelector(
      `#storefront .placeholder-component-dragging[data-uid="${firstComponentUid}"]`,
    );
    const prevDataUid = $currentElement?.previousElementSibling?.getAttribute('data-uid') ?? '-1';
    const nextDataUid = $currentElementDragging?.nextElementSibling?.getAttribute('data-uid') ?? '-1';
    const $listChildren = $currentElement?.querySelectorAll(`[data-uid]`) ?? [];
    const childrenDataUids = Array.from($listChildren).map((child) => child?.getAttribute('data-uid') ?? '-1');

    return { nextDataUid, prevDataUid, childrenDataUids };
  }

  //==================== Mouse Up ==========================
  const onMouseUp = () => {
    if (state.timeoutDragging) clearTimeout(state.timeoutDragging);
    stopAutoScroll();
  };

  const listenMouseUp = () => {
    if (state.timeoutDragging) clearTimeout(state.timeoutDragging);
    stopAutoScroll();

    //clear locked area
    state.rectDragging = null;

    const $sidebarItem = document.body.querySelector<HTMLElement>('.gemx-preset-item[data-preset-id].cursor-grabbing');
    if ($sidebarItem) {
      $sidebarItem.classList.add('cursor-grab');
      $sidebarItem.classList.remove('cursor-grabbing');
    }

    // Clean fake component
    const $iframe = document.body.querySelector<HTMLIFrameElement>(selector.iframe);
    const $oldFakeComponent = document.body.querySelector(selector.fakeComponent);
    if ($oldFakeComponent) {
      $oldFakeComponent.remove();
    }

    // Clear hover parent
    setHoverParentElement(undefined);
    if ($iframe) {
      const dragInfo = getDragInformation(state);
      const firstComponent = dragInfo?.components?.[0] as Component;

      const $iframeDoc = $iframe.contentDocument || ($iframe.contentWindow as any).document;

      const $stickyOverlay = $iframeDoc.querySelector('.sticky-overlay');

      const isDropSticky = firstComponent?.tag == STICKY_TAG || isDragStickySection.value;
      if (isDropSticky && dragInfo && $stickyOverlay) {
        const positionDropSticky = $stickyOverlay.getAttribute('data-position-drag-sticky');
        if (editorStore.getModalActiveId) {
          emitSelectModal();
          editorStore.setActiveModal();
        }
        updateDropElementCount();
        dropTo({
          toComponentUid: 'ROOT',
          toAfterSectionCid: '',
          components: dragInfo?.components,
          direction: 'children',
          isNew: dragInfo.isNew,
          productId: '',
          articleId: '',
          positionDropSticky,
          isDropStickySection: isDropSticky,
        });
        $stickyOverlay?.remove();
      }

      // Clean placeholder
      const $fakePlaceholders = $iframeDoc.querySelectorAll('.fake-placeholder:not(.visual-placeholder)');
      if ($fakePlaceholders?.length) {
        for (let index = 0; index < $fakePlaceholders.length; index++) {
          const $fakePlaceholder = $fakePlaceholders[index];
          $fakePlaceholder.remove();
        }
      }

      // Clean visual component place
      const $fakeComponents = $iframeDoc.querySelectorAll('.fake-visual-component');
      if ($fakeComponents?.length) {
        for (let index = 0; index < $fakeComponents.length; index++) {
          const $fakeComponent = $fakeComponents[index];
          $fakeComponent.remove();
        }
      }

      //** Calc position drop
      const $visualPlaceholder = $iframeDoc.querySelector('.visual-placeholder');
      if ($visualPlaceholder && dragInfo && firstComponent) {
        updateDropElementCount();
        // Allow drop
        if ($visualPlaceholder.classList.contains('visual-placeholder-error')) {
          const { handleAlert } = useNotification();

          if ($visualPlaceholder.classList.contains('exceeded-section')) {
            const learnMoreLink = `<a target="_blank" class="inline-block mt-12 text-dark-500 no-underline bg-light-100 px-16 py-8 rounded-xl hover:bg-light-450" href="https://help.gempages.net/articles/unable-to-save-publish">Learn more</a>`;
            const message = `Unable to add elements due to section <strong>exceeds code size limit</strong>.<br /> You can split it into multiple sections to resolve.<br /> ${learnMoreLink}`;
            handleAlert('error', message, 5000);
          } else if ($visualPlaceholder.classList.contains('max-columns')) {
            const message = `Maximum number of columns is ${MAX_COLUMNS}`;
            handleAlert('error', message, 5000);
          } else {
            // Alert message drop error
            const alertMessages: string[] = [];
            const flowPages = getFlowPages(dragInfo.components);
            if (flowPages?.length) {
              const flowPagesName = [];
              for (let i = 0; i < flowPages.length; i++) {
                const flowPage = flowPages[i];
                if (flowPage && flowPage !== editingPageType.value) {
                  flowPagesName.push(usePageName(flowPage as ThemePageType));
                }
              }
              if (flowPagesName?.length) {
                alertMessages.push(
                  `Please <span class="font-bold">drop</span> ${
                    dragInfo.label
                  } into a <span class="font-bold">${flowPagesName.join(' or ')}</span> page`,
                );
              }
            }

            const builderSettings = builderConfigStore.getBuilderSettings;
            const { flowTags, parentLabel, isHasChild } = getAllChildrensFlowTags(dragInfo.components as Component[]);
            if (flowTags?.length) {
              const flowTagLabels = [];
              for (let i = 0; i < flowTags.length; i++) {
                const flowTag = flowTags[i];
                const configComponent = builderSettings[flowTag];
                const flowLabel = configComponent?.label || flowTag;
                Array.isArray(flowLabel) && flowLabel?.length
                  ? flowTagLabels.push(...flowLabel)
                  : flowTagLabels.push(flowLabel);
              }
              if (flowTagLabels.length) {
                const isProductBundleElement = dragInfo.components?.[0]?.tag === 'ProductBundleDiscount';
                const dropLabel = isHasChild ? `${parentLabel} information` : dragInfo.label;
                if (flowTagLabels.includes('Product') && !flowTagLabels.includes('Product List')) {
                  flowTagLabels.push('Product List');
                }
                let flowLabelsName = generateNameFlowTags(flowTagLabels);
                if (isProductBundleElement) flowLabelsName = 'Product';
                alertMessages.push(
                  `Please <span class="font-bold">drop</span> ${dropLabel} into a <span class="font-bold">${flowLabelsName}</span> element`,
                );
              }
            }

            const { notAppendTags, checkingChildrenTags } = getNotAppendTags(dragInfo.components);
            if (notAppendTags?.length) {
              const notAppendParentTag =
                $visualPlaceholder.getAttribute('data-not-append-parent-tag') ?? notAppendTags[0];
              if (notAppendParentTag) {
                let centerMessage = ` element should not be added within another ${notAppendParentTag}`;
                if (canNotDropHere[notAppendParentTag]) {
                  centerMessage = canNotDropHere[notAppendParentTag].centerText;
                }
                alertMessages.push(
                  `<span class="font-bold">${dragInfo.label} ${
                    checkingChildrenTags.length ? ` with ${checkingChildrenTags.join(' or ')}` : ''
                  }</span>  ${centerMessage}`,
                );
              }
            }
            alertMessages?.length && handleAlert('error', alertMessages[0], 5000);
          }
        } else {
          const componentId = $visualPlaceholder.getAttribute('data-uid');
          const dataDirection = $visualPlaceholder.getAttribute('data-direction');
          const direction: 'before' | 'after' | 'children' = dataDirection;
          const dataAfterId = $visualPlaceholder.getAttribute('data-after-uid');
          const dataProductId = $visualPlaceholder.getAttribute('data-product-id');
          const isPreventDrop =
            $visualPlaceholder.getAttribute('is-drag-to-theme-section') ||
            $visualPlaceholder.getAttribute('is-drag-to-shopify-section');
          const dataArticleId = $visualPlaceholder.getAttribute('data-article-id');
          const marqueeItemKey = $visualPlaceholder.getAttribute('marquee-item-key');
          const dataParentTag = $visualPlaceholder.getAttribute('data-parent-tag');
          const marqueeParentTag = marqueeItemKey ? 'Marquee' : undefined;
          const dragInfoFlowByTag = getDragInformation(state, marqueeParentTag);
          const buildWithSection = dragInfo?.isNew && firstComponent?.tag === SECTION_TAG;
          let buildWithSectionToPopup = false;
          if (buildWithSection && dataParentTag === MODAL_TAG) {
            buildWithSectionToPopup = true;
          }
          if (componentId) {
            dropTo({
              toComponentUid: componentId,
              toAfterSectionCid: dataAfterId,
              components: dragInfoFlowByTag?.components || dragInfo.components,
              direction: direction,
              isNew: dragInfo.isNew,
              productId: dataProductId,
              isPreventDrop: isPreventDrop,
              articleId: dataArticleId,
              marqueeItemKey,
              buildWithSectionToPopup,
            });
          }
          const component = dragInfo.components[0];
          if (dragInfo.isNew && component.tag == 'Product') {
            nextTick(() => {
              useSyncProductProperties().syncCurrentElement();
            });
          }
        }
        // Remove visual drop
        $visualPlaceholder.remove();
        if ((firstComponent as Component).uid) {
          window.dispatchEvent(
            new CustomEvent('elementTextChange', { detail: { uid: (firstComponent as Component).uid } }),
          );
        }
      } else if (firstComponent) {
        // Open edit setting
        const componentUid = firstComponent.uid;
        const sectionId = cacheGetSectionCidByComponentUid(componentUid);
        let productId: string = '';
        let articleId: string = '';
        const $placeholderComponent = $iframeDoc
          .querySelector(`#storefront`)
          ?.querySelector('.placeholder-component-dragging');

        if ($placeholderComponent) {
          productId = $placeholderComponent.getAttribute('data-product-id') ?? '';
          articleId = $placeholderComponent.getAttribute('data-article-id') ?? '';
        }
        onOpenSetting(componentUid, sectionId, productId, articleId);
      }

      // Disable user-select when drag
      const $storefront = $iframeDoc.querySelector(`#storefront`);
      if ($storefront) {
        $storefront.style.userSelect = null;

        if (state.component) {
          // Change position
          const component = state.component;
          const $currentComponents = $storefront.querySelectorAll(`[data-uid="${component.uid}"]`);
          for (const $currentComponent of $currentComponents) {
            if ($currentComponent) {
              // Show component after change position
              $currentComponent.style.display = null;
              $currentComponent.style.cursor = null;
              // $currentComponent.classList.remove('component-dragging');
              removeFlagComponentDragging($currentComponent);
              // Remove placeholder component anchor
              const $placeholderComponents = $storefront.querySelectorAll('.placeholder-component-dragging');
              if ($placeholderComponents?.length) {
                for (let d = 0; d < $placeholderComponents.length; d++) {
                  const $placeholderComponent = $placeholderComponents[d];
                  $placeholderComponent.remove();
                }
              }
            }
          }
        }

        if ($iframeDoc?.window?.getSelection) {
          $iframeDoc.window.getSelection().removeAllRanges();
        } else if ($iframeDoc?.getSelection) {
          $iframeDoc.getSelection().empty();
        }
      }

      const $visualContent = $iframeDoc.querySelector('#visual-content');
      if ($visualContent) {
        $visualContent.style.display = 'none';
        $visualContent.style.cursor = 'auto';
      }

      const $iframeBody = $iframeDoc.querySelector('body');
      if ($iframeBody) {
        $iframeBody.style.cursor = 'auto';
        $iframeBody.classList.remove('select-none');
      }
      if (document.body) {
        document.body.style.cursor = 'auto';
        document.body.classList.remove('select-none');
      }
    }

    // Clean state
    editorStore.setDragging(false);
    state.component == null; // Reset
    state.newPreset = null; // Reset
    sectionStore.setSectionDragging('');
    document.removeEventListener('mousemove', listenMouseMoveDocument);
    document.removeEventListener('mouseup', listenMouseUp);
    if ($iframe) {
      // Disable watch mousemove warper iframe. Fix for safari 17.2.1
      $iframe.removeEventListener('mousemove', listenMouseMoveDocumentIframe);
      $iframe.removeEventListener('mouseup', listenMouseUp);

      const $iframeDoc = $iframe.contentDocument ?? ($iframe.contentWindow as any).document;
      if ($iframeDoc) {
        $iframeDoc.removeEventListener('mousemove', listenMouseMoveIframe);
        $iframeDoc.removeEventListener('mouseup', listenMouseUp);
      }
    }
  };

  //==================== Scroll Event ==========================
  const startAutoScroll = (event: MouseEvent) => {
    updateClientY(event);
    if (state.timeoutAutoScroll) clearTimeout(state.timeoutAutoScroll);
    state.timeoutAutoScroll = setTimeout(() => {
      if (state.idRequestAnimationFrame) cancelAnimationFrame(state.idRequestAnimationFrame);
      state.idRequestAnimationFrame = requestAnimationFrame(initAutoScroll);
    }, 500);
  };

  const stopAutoScroll = () => {
    if (state.timeoutAutoScroll) clearTimeout(state.timeoutAutoScroll);
    if (state.idRequestAnimationFrame) cancelAnimationFrame(state.idRequestAnimationFrame);
    updateClientY();
  };

  const initAutoScroll = () => {
    const { iframe: $iframe, iframeWin: $iframeWin, iframeDoc: $iframeDoc } = getCustomWindow();
    if (!$iframe || !$iframeWin) return;

    const dataParentTag = $iframeDoc.querySelector('.visual-placeholder')?.getAttribute('data-parent-tag');
    if (dataParentTag == STICKY_TAG) {
      state.isAutoScroll = false;
      return;
    }

    state.isAutoScroll = true;
    const rect = $iframe?.getBoundingClientRect();
    handleScrollPosition($iframeWin, rect, state.lastClientY);

    if (state.idRequestAnimationFrame) cancelAnimationFrame(state.idRequestAnimationFrame);
    if (editorStore.getDragging) {
      state.idRequestAnimationFrame = requestAnimationFrame(initAutoScroll);
    }
  };

  const handleScrollPosition = ($iframeWin: Window, rect: DOMRect, clientY?: number) => {
    if (!clientY) return;
    let velocityScroll = null;

    if (clientY <= MAX_CLIENT_Y) {
      velocityScroll = -calcScrollVelocity(clientY);
    } else if (rect.height - clientY <= MAX_CLIENT_Y) {
      velocityScroll = calcScrollVelocity(rect.height - clientY);
    }

    if (velocityScroll) {
      $iframeWin.scrollBy({
        top: velocityScroll,
        left: 0,
        behavior: 'instant' as ScrollBehavior,
      });
    }
  };

  const calcScrollVelocity = (clientY: number) => {
    const maxRate = 20;
    const minRate = 3;
    const rate = maxRate - (clientY * (maxRate - minRate)) / MAX_CLIENT_Y;
    return Math.round(rate);
  };

  const updateClientY = (event?: MouseEvent) => {
    state.lastClientY = event?.clientY || undefined;
  };

  //==================== Private Function ==========================
  const initComponent = (input: ComponentConfig & { uid?: string }): Component[] => {
    const builderSettings = builderConfigStore.getBuilderSettings;
    return initNewComponent(input.tag, builderSettings);
  };

  const getCustomWindow = () => {
    const $iframe = document.body.querySelector<HTMLIFrameElement>(selector.iframe);
    const $iframeDoc = $iframe?.contentDocument || ($iframe?.contentWindow as any)?.document;
    const $iframeWin = $iframe?.contentWindow;
    return {
      iframeDoc: $iframeDoc || null,
      iframe: $iframe || null,
      iframeWin: $iframeWin || null,
    };
  };

  // ============================ EXPORT ================================
  return {
    initNewComponent: initComponent,
    sidebarItemOnMouseDown(e: any) {
      onMouseDown(e, 0, 'document');
    },
    sidebarItemOnMouseUp: onMouseUp,
    toolbarOnMouseDown(e: any, delay = 200) {
      onMouseDown(e, delay, 'document');
    },
    toolbarOnMouseUp: onMouseUp,
    listenIframe() {
      // Drag & drop component in Iframe
      const $iframe = document.body.querySelector<HTMLIFrameElement>(selector.iframe);
      if ($iframe) {
        const $iframeDoc = $iframe.contentDocument || ($iframe.contentWindow as any).document;
        const $components: HTMLElement[] = $iframeDoc.body?.querySelectorAll('[data-component-type="component"]');
        if ($components?.length) {
          for (let i = 0; i < $components.length; i++) {
            const $component = $components[i];
            if ($component.getAttribute('data-uid') !== 'ROOT') {
              $component.removeEventListener('mousedown', listenMouseDownIframe);
              $component.removeEventListener('mouseup', onMouseUp);
              if (isBlockedByInteraction.value) continue;
              $component.addEventListener('mousedown', listenMouseDownIframe);
              $component.addEventListener('mouseup', onMouseUp);
            }
          }
        }
      }
    },
  };
}

/**
 * Các vị trí có thể thả:
 * - Trên dưới một section -> tạo thành một section mới nằm trên hoặc dưới section đó
 * - Trên dưới trái phải một component
 * - Dưới cùng của trang -> tạo thành một section mới
 * - Trái phải một collumn -> thêm cột vào row chứa collumn đó
 *
 * Một số trường hợp đặc biệt
 * - Section chỉ kéo trên dưới section, không được phép lồng section và trong section
 * - Một số element chỉ được kéo vào cha đã được quy định, ví dụ: product title chỉ được kéo vào cha product
 */
const dropTo = ({
  toComponentUid,
  toAfterSectionCid,
  components,
  isNew,
  direction,
  productId,
  articleId,
  positionDropSticky,
  isPreventDrop,
  marqueeItemKey,
  buildWithSectionToPopup,
  isDropStickySection,
}: {
  toComponentUid: string;
  toAfterSectionCid: string | undefined;
  components: Component[] | InitComponentType[];
  isNew: boolean;
  direction: PlaceHolder['direction'];
  productId?: string;
  articleId?: string;
  positionDropSticky?: PositionDropSticky;
  isPreventDrop?: boolean;
  marqueeItemKey?: string;
  buildWithSectionToPopup?: boolean;
  isDropStickySection?: boolean;
}) => {
  // Get position drop
  const toComponentTag = cacheGetComponentTagByComponentUid(toComponentUid);
  const firstComponent = components?.[0];
  const firstComponentTag = firstComponent.tag;
  if (isPreventDrop) return;

  if (firstComponentTag === STICKY_TAG || isDropStickySection) {
    dropToRoot({ isNew, components, direction, toAfterSectionCid, positionDropSticky, isDropStickySection });
  } else if (firstComponentTag === MODAL_TAG || toComponentUid == ROOT_TAG) {
    dropToRoot({ isNew, components, direction, toAfterSectionCid });
  } else if ((firstComponentTag === SECTION_TAG || toComponentTag === SECTION_TAG) && !buildWithSectionToPopup) {
    dropToSection({ isNew, components, toComponentUid, direction, toAfterSectionCid });
  } else {
    dropToComponent({
      isNew,
      components,
      toComponentUid,
      direction,
      productId,
      articleId,
      marqueeItemKey,
      buildWithSectionToPopup,
    });
  }
};

/**
 * Những trường hợp được phép thả vào section
 * - Kéo một section khác lên trên/dưới các section còn lại
 * - Kéo một element ra bên ngoài section thì sẽ tạo một section bọc lấy element đó
 * - Kéo mới một preset vào giữa các sections
 */
const dropToSection = ({
  toAfterSectionCid,
  toComponentUid,
  components,
  isNew,
  direction,
  stickyPosition,
}: {
  toAfterSectionCid: string | undefined;
  toComponentUid: string;
  components: Component[] | InitComponentType[];
  isNew: boolean;
  direction: PlaceHolder['direction'];
  isDropStickySection?: boolean;
  stickyPosition?: PositionDropSticky;
}) => {
  const editorStore = useEditorStore();
  const sectionStore = useSectionStore();
  const builderConfigStore = useBuilderConfigStore();
  const { handleError } = useNotification();
  const { isPostPurchasePage } = useSaleFunnel();
  const { createBuildWithSection } = useBuildWithSection();

  // Get position drop
  const firstComponent = components?.[0];
  const toSectionCid = cacheGetSectionCidByComponentUid(toComponentUid);

  if (firstComponent.tag === SECTION_TAG && !isNew) {
    // Case: Section change position between sections
    const component = firstComponent as Component;
    const sectionCid = cacheGetSectionCidByComponentUid(component.uid);
    if (direction == 'after') {
      const beforeFromSection = sectionStore.getSectionBefore(sectionCid);
      if (beforeFromSection?.cid != toSectionCid) {
        sectionMove(sectionCid, toAfterSectionCid || toSectionCid);
      }
    } else {
      const beforeToSection = sectionStore.getSectionBefore(toAfterSectionCid || toSectionCid);
      if (sectionCid != beforeToSection?.cid) {
        sectionMove(sectionCid, beforeToSection?.cid || null);
      }
    }
    onOpenSetting((firstComponent as Component).uid, toSectionCid, '');
  } else if (firstComponent.tag === SECTION_TAG && isNew) {
    // build with section
    const sectionDragging = sectionStore.getSectionDragging
      ? convertComponentToJSON(sectionStore.getSectionDragging)
      : '';
    const buildWithSectionDirection = ['before', 'after'].includes(direction ?? '') ? direction : 'after';
    const newSectionCid = toAfterSectionCid || toSectionCid;

    if (!sectionDragging) {
      createBuildWithSection(
        true,
        components as Component[],
        buildWithSectionDirection as 'after' | 'before',
        newSectionCid,
      );
      const cb = (sectionDragging: string) => {
        const cpn = convertComponentToJSON(sectionDragging);
        if (!cpn) return;

        if (cpn.tag === STICKY_TAG) {
          cpn.settings = {
            ...cpn.settings,
            position: {
              desktop: stickyPosition || 'top',
            },
          };
        }
        createBuildWithSection(false, [cpn], buildWithSectionDirection as 'after' | 'before', newSectionCid);
        event.off('build-with-section-loaded-success', cb);
      };
      event.on('build-with-section-loaded-success', cb);
      return;
    }

    if (sectionDragging.tag === STICKY_TAG) {
      sectionDragging.settings = {
        ...sectionDragging.settings,
        position: {
          desktop: stickyPosition || 'top',
        },
      };
    }

    createBuildWithSection(false, [sectionDragging], buildWithSectionDirection as 'after' | 'before', newSectionCid);
  } else if (isNew) {
    // Case: Kéo mới vào giữa section và kéo ra ngoài section
    const builderSettingSection = builderConfigStore.getBuilderSettingByTag(SECTION_TAG);
    let newSectionComponents: Component = {} as Component;
    if (fullWidthComponentTags.includes(firstComponent.tag) && !isPostPurchasePage.value) {
      newSectionComponents = initNewComponent(builderSettingSection.tag, {}, true)[0];
    } else {
      newSectionComponents = initNewComponent(builderSettingSection.tag)[0];
    }
    let newComponents: Component[] = [];
    newComponents = initNewPreset(components, {
      allowSectionOverride: true,
    });
    newComponents = changePresetRowMobile(newComponents);
    handleTrackingSearchResult(true, true, newComponents[0].tag, newComponents[0].uid); // Tracking
    newSectionComponents.childrens = newSectionComponents.childrens || [];
    newSectionComponents.childrens[0].childrens = newComponents;

    if (noSpacingComponentTags.includes(firstComponent.tag)) {
      newSectionComponents.advanced = {
        ...newSectionComponents.advanced,
        'spacing-setting': {},
      };
    }
    if (sectionFullWidthForComponentTags.includes(firstComponent.tag)) {
      newSectionComponents.styles = {
        ...newSectionComponents.styles,
        fullWidth: {
          desktop: true,
          mobile: true,
          tablet: true,
        },
      };
    }

    const newSection = initNewSection({
      components: [newSectionComponents],
      isMobile: editorStore.mobileOnly,
    });

    const newSectionCid = toAfterSectionCid || toSectionCid;
    sectionCreate(newSection, {
      cid: newSectionCid,
      direction: direction == 'before' ? 'before' : 'after',
    });

    if (newComponents.length > 0) {
      onOpenSetting(newComponents[0].uid, newSectionCid, '');
    }
    changeActiveCoupon(firstComponent, newComponents, newSectionCid);
  } else {
    // Case: Kéo component đang có vào giữa section

    const firstComponent = components[0] as Component;
    const fromComponent = cloneDeepObject(firstComponent);
    const fromSectionCid = cacheGetSectionCidByComponentUid(fromComponent.uid);
    const fromSection = sectionStore.getItemByCid(fromSectionCid);
    if (!fromSection) {
      sentryCaptureException('dropToSection', "Can't find section by cid", {
        firstComponent,
      });
      handleError("Can't find section by cid");
      return;
    }

    // Change data component
    let newComponentFrom = fromSection?.component || '';
    // remove from
    const updateComponentFrom = updateComponentUsingCommand({
      component: newComponentFrom,
      commands: [{ type: 'remove_component', componentUid: fromComponent.uid }],
    });
    if (updateComponentFrom?.component) {
      newComponentFrom = updateComponentFrom.component;
    }

    // New section with current component drag
    const builderSettingSection = builderConfigStore.getBuilderSettingByTag(SECTION_TAG);
    let newSectionComponents: Component = {} as Component;
    if (fullWidthComponentTags.includes(firstComponent.tag)) {
      newSectionComponents = initNewComponent(builderSettingSection.tag, {}, true)[0];
    } else {
      newSectionComponents = initNewComponent(builderSettingSection.tag)[0];
    }
    newSectionComponents.childrens = newSectionComponents.childrens || [];
    newSectionComponents.childrens[0].childrens = [fromComponent];
    if (noSpacingComponentTags.includes(firstComponent.tag)) {
      newSectionComponents.advanced = {
        ...newSectionComponents.advanced,
        'spacing-setting': {},
      };
    }
    if (sectionFullWidthForComponentTags.includes(firstComponent.tag)) {
      newSectionComponents.styles = {
        ...newSectionComponents.styles,
        fullWidth: {
          desktop: true,
          mobile: true,
          tablet: true,
        },
      };
    }

    const newSection = initNewSection({
      components: [newSectionComponents],
      isMobile: editorStore.mobileOnly,
    });

    moveComponentToNewSection({
      componentUid: firstComponent.uid,
      fromCid: fromSection.cid || '',
      fromComponent: newComponentFrom,
      newSection,
      position: {
        cid: toAfterSectionCid || toSectionCid,
        direction: direction == 'before' ? 'before' : 'after',
      },
    });
  }
};

/**
 * Những trường hợp được thả vào trên dưới một component:
 * - Kéo component đã có trên trang sang một vị trí khác, vị trí mới có thể trong section hoặc sang section khác
 * - Kéo mới một preset vào bên dưới một component
 */
const dropToComponent = ({
  toComponentUid,
  components,
  isNew,
  direction,
  productId,
  articleId,
  marqueeItemKey,
  buildWithSectionToPopup,
}: {
  toComponentUid: string;
  components: Component[] | InitComponentType[];
  isNew: boolean;
  direction: PlaceHolder['direction'];
  productId?: string;
  articleId?: string;
  marqueeItemKey?: string;
  buildWithSectionToPopup?: boolean;
}) => {
  const sectionStore = useSectionStore();
  const { handleError } = useNotification();
  const { updateSettingProductBadge } = useUpdateProductBadge();
  const isSectionRowProduct = (tag: string) => {
    return tag === SECTION_TAG || tag === ROW_TAG || tag === 'Product';
  };

  const verifyMaxColumns = (sectionComponent: string | undefined, toComponentUid: string) => {
    if (!sectionComponent) return;
    const jsonComponent = convertComponentToJSON(sectionComponent);
    if (!jsonComponent) return;
    if (direction === 'left' || direction === 'right') {
      const parent = getParentComponentByUid(jsonComponent, toComponentUid);
      if (!parent) return;

      const rowParent = getParentComponentByUid(jsonComponent, parent?.uid);

      if (!isSectionRowProduct(parent.tag) && (!rowParent || !isSectionRowProduct(rowParent.tag))) return;

      const childs = parent.childrens || [];
      const rowParentChilds = (rowParent && rowParent.childrens) || [];

      const isMaxColumns =
        (isSectionRowProduct(parent.tag) && childs.length >= MAX_COLUMNS) ||
        (rowParent &&
          isSectionRowProduct(rowParent.tag) &&
          rowParentChilds.length >= MAX_COLUMNS &&
          childs.length === 1);
      if (isMaxColumns) {
        const { handleAlert } = useNotification();
        const message = `Maximum number of columns is ${MAX_COLUMNS}`;
        handleAlert('error', message, 5000);
        return true;
      }
    }
  };

  if (isNew) {
    // Case: Kéo một preset mới
    const toSectionCid = cacheGetSectionCidByComponentUid(toComponentUid);
    const toSection = sectionStore.getItemByCid(toSectionCid);
    if (!toSection) {
      sentryCaptureException('dropToComponent', "Can't find section by cid", {
        toComponentUid,
        toSectionCid,
      });
      handleError("Can't find section by cid");
      return;
    }

    let newComponents: Component[];
    if (buildWithSectionToPopup) {
      const sectionStore = useSectionStore();
      const { convertSectionComponentWhenInsert, createRowWrapper } = useInsertTemplate();
      const sectionDragging = sectionStore.getSectionDragging
        ? convertComponentToJSON(sectionStore.getSectionDragging)
        : '';
      if (!sectionDragging) return;

      newComponents = createRowWrapper(sectionDragging);
      convertSectionComponentWhenInsert(newComponents[0], 'library_sections');
      toSection.isImportFromLibrary = true;
    } else {
      newComponents = initNewPreset(components);
    }
    newComponents = changePresetRowMobile(newComponents);

    handleTrackingSearchResult(true, true, newComponents[0].tag, newComponents[0].uid); // Tracking
    let newSectionComponent = toSection.component;

    if (verifyMaxColumns(newSectionComponent, toComponentUid)) return;

    updateSettingProductBadge(newComponents[0], toComponentUid, direction || '', newSectionComponent);

    if (newSectionComponent) {
      // Change content component
      const updateComponent = updateComponentUsingCommand({
        component: newSectionComponent,
        commands: [
          {
            type: 'add_component',
            newComponents: newComponents,
            toComponentUid: toComponentUid,
            direction,
          },
        ],
      });
      newSectionComponent = updateComponent?.component;
      // Save to store
      if (newSectionComponent) {
        sectionUpdateComponent({
          cid: toSectionCid,
          newComponent: newSectionComponent,
        });
      }

      // Open edit setting
      if (newComponents.length > 0) {
        onOpenSetting(newComponents[0].uid, toSectionCid, productId ?? '', articleId ?? '', marqueeItemKey);
      }
      changeActiveCoupon(components[0], newComponents, toSectionCid);
    }
  } else {
    // Case: Kéo component đã có trên trang sang một vị trí khác, vị trí mới có thể trong section hoặc sang section khác

    const firstComponent = components?.[0] as Component;

    // From
    const fromComponent = cloneDeepObject(firstComponent);
    const fromSectionCid = cacheGetSectionCidByComponentUid(fromComponent.uid);
    const fromSection = sectionStore.getItemByCid(fromSectionCid);
    if (!fromSection) {
      sentryCaptureException('dropToComponent', "Can't find section by cid", {
        fromComponent,
        fromSectionCid,
      });
      handleError("Can't find section by cid");
      return;
    }

    // To
    const toSectionCid = cacheGetSectionCidByComponentUid(toComponentUid);
    const toSection = sectionStore.getItemByCid(toSectionCid);
    if (!toSection) {
      sentryCaptureException('dropToComponent', "Can't find section by cid", {
        toComponentUid,
        toSectionCid,
      });
      handleError("Can't find section by cid");
      return;
    }

    let newComponentFrom = fromSection.component;
    let newComponentTo = toSection.component;

    if (verifyMaxColumns(newComponentTo, toComponentUid)) return;

    updateSettingProductBadge(fromComponent, toComponentUid, direction || '', newComponentTo);

    if (fromSection?.cid == toSection.cid) {
      // If drag drop in section
      // Validate drag drop current position
      if (toComponentUid != fromComponent.uid) {
        //update current component
        const updateComponentTo = updateComponentUsingCommand({
          component: newComponentTo || '',
          commands: [
            { type: 'remove_component', componentUid: fromComponent.uid },
            {
              type: 'add_component',
              newComponents: [fromComponent],
              toComponentUid: toComponentUid,
              direction,
            },
          ],
        });
        newComponentTo = updateComponentTo?.component;
        if (newComponentTo) {
          sectionUpdateComponent({
            cid: toSectionCid,
            newComponent: newComponentTo,
          });
        }
      }
    } else {
      // If drag drop outside section
      if (newComponentFrom && fromComponent) {
        // remove from
        const updateComponentFrom = updateComponentUsingCommand({
          component: newComponentFrom || '',
          commands: [{ type: 'remove_component', componentUid: fromComponent.uid }],
        });
        newComponentFrom = updateComponentFrom?.component;

        // add to
        const updateComponentTo = updateComponentUsingCommand({
          component: newComponentTo || '',
          commands: [
            {
              type: 'add_component',
              newComponents: [fromComponent],
              toComponentUid: toComponentUid,
              direction,
            },
          ],
        });
        newComponentTo = updateComponentTo?.component;

        // Save to store
        if (newComponentFrom && newComponentTo) {
          sectionMoveComponent({
            fromCid: fromSectionCid,
            fromNewComponent: newComponentFrom,
            toCid: toSectionCid,
            toNewComponent: newComponentTo,
          });
        }
      }
    }
    onOpenSetting(fromComponent.uid, toSectionCid, productId ?? '');
  }
};

/**
 * Những trường hợp được thả vào root
 * - Kéo mới một component popup
 * - Kéo một element từ một section ra root để tạo section mới
 * - Kéo một preset vào root và tạo một section bọc preset
 */
const dropToRoot = async ({
  isNew,
  components,
  direction,
  toAfterSectionCid,
  positionDropSticky,
  isDropStickySection,
}: {
  isNew: boolean;
  components: Component[] | InitComponentType[];
  direction: PlaceHolder['direction'];
  toAfterSectionCid: string | undefined;
  positionDropSticky?: PositionDropSticky;
  isDropStickySection?: boolean;
}) => {
  const editorStore = useEditorStore();
  const firstComponent = components?.[0] as Component;

  if (firstComponent.tag == STICKY_TAG) {
    if (isNew) {
      const settings = {
        ...firstComponent.settings,
        position: {
          desktop: positionDropSticky,
        },
      };
      const parseComponent: InitComponentType[] = [
        {
          ...firstComponent,
          settings,
        },
      ];
      const newComponents = initNewPreset(parseComponent);
      const newSection = initNewSection({
        components: newComponents,
        isMobile: editorStore.mobileOnly,
        options: {
          tag: newComponents[0].tag,
        },
      });
      const stickies = useSectionStore().stickies;
      let cId;
      if (stickies.length) {
        cId = stickies.pop()?.cid;
      }
      sectionCreate(newSection, {
        cid: cId,
        direction: cId ? 'after' : 'before',
      });
      if (newComponents.length > 0) {
        const componentUid = newComponents[0].uid;
        // trigger open setting product in sticky to get latest productId
        const productUid = newComponents[0]?.childrens?.[0]?.childrens?.[0].uid ?? '';
        const sectionCid = cacheGetSectionCidByComponentUid(componentUid);
        await sleep(0);
        onOpenSetting(productUid, sectionCid, '');
        await sleep(0);
        onOpenSetting(componentUid, sectionCid, '');
        await sleep(500);
        useSyncProductProperties().syncCurrentElement(productUid);
      }
    } else {
      const componentUid = firstComponent.uid;
      const editingSectionUid = cacheGetSectionCidByComponentUid(componentUid);
      const screenId = editorStore.getScreenActiveId;
      controlChange({
        sectionCid: editingSectionUid,
        settings: [
          {
            componentUid: componentUid,
            controlId: 'position',
            groupType: 'setting',
            controlType: 'layout-segment',
            newValue: positionDropSticky,
            screenId: screenId,
            hasDevices: true,
          },
        ],
        options: {
          noApplyToPreview: false,
        },
      });
    }
  } else if (firstComponent.tag == MODAL_TAG) {
    // Add dialog to ROOT
    const newDialogComponents = initNewPreset(components);
    if (newDialogComponents.length > 0 && newDialogComponents[0].settings) {
      // Set name for dynamic for dialog
      newDialogComponents[0].settings.name = `Popup ${useSectionStore().modals.length + 1}`;
    }

    const newSection = initNewSection({
      components: newDialogComponents,
      isMobile: editorStore.mobileOnly,
      options: {
        tag: newDialogComponents[0].tag,
      },
    });

    sectionCreate(newSection, {
      cid: undefined,
      direction: 'after',
    });

    if (newDialogComponents.length > 0) {
      const modalId = newDialogComponents[0].uid;
      emitSelectModal(modalId);
      editorStore.setActiveModal(modalId);
      editorStore.setEditingComponentUid(modalId);
    }
  } else {
    // Case: Kéo mới preset hoặc kéo element ra root giống với case kéo ra bên ngoài section, nhưng vị trí luôn luôn ở cuối trang
    dropToSection({
      components,
      isNew,
      direction: isDropStickySection ? 'before' : direction,
      toAfterSectionCid,
      toComponentUid: 'undefined', // uid undefined -> append bottom
      isDropStickySection,
      stickyPosition: positionDropSticky,
    });
  }
};
const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
// const dropToColumn = ({ isNew }: { isNew: boolean }) => {};
// ============================= End save data ======================

const setHoverParentElement = ($el: Element | undefined) => {
  const $iframe = document.body.querySelector<HTMLIFrameElement>(selector.iframe);
  if ($iframe) {
    const $iframeDoc = $iframe.contentDocument || ($iframe.contentWindow as any).document;
    if ($iframeDoc) {
      if (!$el) {
        const $isForceHover = $iframeDoc.querySelectorAll(
          '[data-toolbar-force-hover],[data-outline-force-hover]',
        ) as Element[];
        if ($isForceHover.length) {
          $isForceHover.forEach(($item) => {
            $item.removeAttribute('data-toolbar-force-hover');
            $item.removeAttribute('data-outline-force-hover');
          });
        }
      } else {
        const $parent = $el?.parentElement?.closest(`[data-component-type="component"]:not([data-toolbar-hide])`);

        if ($parent) {
          // clear diff element
          const $isForceHover = $iframeDoc.querySelectorAll(
            '[data-toolbar-force-hover],[data-outline-force-hover]',
          ) as Element[];
          if ($isForceHover.length) {
            $isForceHover.forEach(($item) => {
              const $parentItem = $item?.closest(`[data-component-type="component"]:not([data-toolbar-hide])`);
              if ($parentItem !== $parent) {
                $item.removeAttribute('data-toolbar-force-hover');
                $item.removeAttribute('data-outline-force-hover');
              }
            });
          }

          // add hover status
          const tag = $parent.getAttribute('data-component-tag');
          if (tag && tag == 'Root') return;

          const $toolbar = getChildrenByAttrSelector($parent, 'data-toolbar');
          const $outline = getChildrenByAttrSelector($parent, 'data-outline');
          if ($toolbar) {
            $toolbar.removeAttribute('style');
            $toolbar.setAttribute('data-toolbar-force-hover', 'true');
          }
          if ($outline) {
            $outline.setAttribute('data-outline-force-hover', 'true');
          }
        }
      }
    }
  }
};

function isComponentVisible(e: any) {
  return !(e.offsetParent === null);
}

function flagComponentDragging($node: Element) {
  $node?.classList.add('component-dragging');

  if (!$node?.hasChildNodes()) {
    return;
  }

  const children = $node?.children || [];

  for (let index = 0; index < children.length; index++) {
    flagComponentDragging(children[index]);
  }
}

function removeFlagComponentDragging($node: Element) {
  $node?.classList.remove('component-dragging');
  if (!$node.hasChildNodes()) {
    return;
  }

  const children = $node?.children || [];
  for (let index = 0; index < children.length; index++) {
    removeFlagComponentDragging(children[index]);
  }
}

const findPlaceholderComponents = (
  $parent: Element,
  scrollTop: number,
  components?: Component[] | InitComponentType[],
) => {
  const placeholders: PlaceHolder[] = [];
  // Add placeholder to before/after component
  const parentTag = $parent.getAttribute('data-component-tag') ?? '';
  const { paddingLeft, paddingTop } = getIframeBodyPadding();

  // drop to section exceeded
  const isSectionExceeded = $parent.getAttribute('data-section-exceeded') ?? '';
  if (isSectionExceeded) {
    setHoverParentElement($parent);
    const dataId = $parent.getAttribute('data-uid') ?? '';
    const rect = $parent?.getBoundingClientRect();
    placeholders.push({
      uid: dataId,
      appendChild: true,
      appendBeforeAfter: true,
      appendLeftRight: false,
      top: rect.top + scrollTop - paddingTop,
      left: rect.left - paddingLeft,
      width: rect.width,
      height: rect.height,
      isExceededSection: true,
      tag: 'section-exceeded',
    });
    return placeholders;
  }

  // If drag element into Theme Section, only show placeholder before/after Theme Section.
  const isPlaceholderInThemeSection = $parent.getAttribute('data-theme-section');
  if (isPlaceholderInThemeSection) {
    const dataId = $parent.getAttribute('data-uid') ?? '';
    const rect = $parent?.getBoundingClientRect();
    placeholders.push({
      uid: dataId,
      appendChild: true,
      appendBeforeAfter: true,
      appendLeftRight: false,
      top: rect.top + scrollTop - paddingTop,
      left: rect.left - paddingLeft,
      width: rect.width,
      height: rect.height,
      isThemeSection: true,
      tag: 'theme-section',
    });
    return placeholders;
  }

  // If drag element into Shopify section, only show placeholder before/after Shopify section.
  const isPlaceholderInShopifySection = $parent.getAttribute('data-shopify-section');
  if (isPlaceholderInShopifySection) {
    const dataId = $parent.getAttribute('data-uid') ?? '';
    const rect = $parent?.getBoundingClientRect();
    placeholders.push({
      uid: dataId,
      appendChild: true,
      top: rect.top + scrollTop - paddingTop,
      left: rect.left - paddingLeft,
      width: rect.width,
      height: rect.height,
      isShopifySection: true,
    });
    return placeholders;
  }

  const $componentAnchors = $parent.querySelectorAll(
    '[data-uid][data-component-type="component"][data-not-droppable="false"]',
  );

  if ($componentAnchors.length) {
    for (let index = 0; index < $componentAnchors.length; index++) {
      const $component = $componentAnchors[index];
      const isParentNotDroppable = !!$component.closest(
        '[data-uid][data-component-type="component"][data-not-droppable="true"]',
      );
      if ($component && isComponentVisible($component) && !isParentNotDroppable) {
        const dataId = $component.getAttribute('data-uid');
        const $marqueeItem = $component.closest('[data-component-tag="MarqueeItem"]');
        let marqueeItemKey = '';
        if ($marqueeItem) {
          const hiddenMarqueeItem = $component.closest('[aria-hidden="true"]');
          const $marqueItemWrapper = $component.closest('[marquee-item-key]');
          marqueeItemKey = $marqueItemWrapper?.getAttribute('marquee-item-key') || '';
          if (hiddenMarqueeItem) {
            continue;
          }
        }
        const productId = $component.closest('[data-component-tag="Product"]')?.getAttribute('data-product-id') ?? '';
        const articleId = $component.closest('[data-component-tag="Article"]')?.getAttribute('data-article-id') ?? '';
        if (dataId) {
          const appendBeforeAndAfter = !$component.getAttribute('data-placeholder-not-append-before-after');
          const appendLeftAndRight = !$component.getAttribute('data-placeholder-not-append-left-right');
          // Append to component
          if (appendBeforeAndAfter || appendLeftAndRight) {
            const rect = $component?.getBoundingClientRect();
            const style = window.getComputedStyle($component);
            const tag = $component.getAttribute('data-component-tag') || '';
            placeholders.push({
              uid: dataId,
              appendChild: false,
              appendBeforeAfter: appendBeforeAndAfter,
              appendLeftRight: appendLeftAndRight,
              top: rect.top + scrollTop - paddingTop,
              left: rect.left - paddingLeft,
              width: rect.width,
              height: rect.height,
              margin: {
                top: style.marginTop,
                bottom: style.marginBottom,
              },
              productId,
              articleId,
              parentTag,
              tag,
              marqueeItemKey,
            });
          }
        }

        // Check if all children are hidden
        const dataSlotsChildren = $component.getAttribute('data-slots-children');
        if (dataSlotsChildren) {
          const $allCols = Array.from($component.querySelectorAll('[data-component-tag="Col"]'));

          for (const $col of $allCols) {
            const $childsOfCol = Array.from($col.children).filter((child) => child.tagName === 'DIV'); // Direct children
            const hasDataSlotChildren = $childsOfCol.some((child) => child.getAttribute('data-slot') === 'children');

            if (!hasDataSlotChildren) {
              const allChildrenHidden = $childsOfCol.every((child) => !isComponentVisible(child));

              if (allChildrenHidden) {
                const dataId = $col.getAttribute('data-uid');
                const rectCol = $col.getBoundingClientRect();
                if (dataId) {
                  const tag = $col.getAttribute('data-component-tag') || '';
                  const parentTag = $component.getAttribute('data-component-tag') || '';
                  const height = 60;
                  placeholders.push({
                    uid: dataId,
                    appendChild: true,
                    appendBeforeAfter: false,
                    appendLeftRight: false,
                    top: rectCol.top - height / 2 + scrollTop - paddingTop,
                    left: rectCol.left - paddingLeft,
                    width: rectCol.width,
                    height,
                    productId,
                    articleId,
                    parentTag,
                    tag,
                    marqueeItemKey,
                  });
                }
              }
            }
          }
        }
      }
    }
  }

  // Add placeholder to children component
  const $slotAnchors = $parent.querySelectorAll('[data-slot="children"]');
  if ($slotAnchors.length) {
    for (let index = 0; index < $slotAnchors.length; index++) {
      const $anchor = $slotAnchors[index];
      const $component = $anchor.closest('[data-uid][data-component-type="component"][data-not-droppable="false"]');
      const isParentNotDroppable = !!$anchor.closest(
        '[data-uid][data-component-type="component"][data-not-droppable="true"]',
      );

      // Can't drop to slot child of the self component or parent is self
      const componentDrag = components?.[0] as Component;
      if (componentDrag?.uid) {
        const $currentComponent = $anchor.closest(`[data-uid="${componentDrag?.uid}"]`);
        if ($currentComponent) {
          continue;
        }
      }

      if ($component && isComponentVisible($component) && !isParentNotDroppable) {
        const dataId = $component.getAttribute('data-uid');
        const productId = $component.closest('[data-component-tag="Product"]')?.getAttribute('data-product-id') ?? '';
        const articleId = $component.closest('[data-component-tag="Article"]')?.getAttribute('data-article-id') ?? '';
        let marqueeItemKey = '';
        const $marqueeItem = $component.closest('[data-component-tag="MarqueeItem"]');
        if ($marqueeItem) {
          const hiddenMarqueeItem = $component.closest('[aria-hidden="true"]');
          const $marqueItemWrapper = $component.closest('[marquee-item-key]');
          marqueeItemKey = $marqueItemWrapper?.getAttribute('marquee-item-key') || '';
          if (hiddenMarqueeItem) {
            continue;
          }
        }
        if (dataId) {
          let isSlot = false;

          const children = $component.children;
          if (children.length > 0 && children[0].getAttribute('data-slot') === 'children') {
            isSlot = true;
          }
          const rect = $anchor?.getBoundingClientRect();
          const tag = $component.getAttribute('data-component-tag') || '';
          placeholders.push({
            uid: dataId,
            appendChild: true,
            appendBeforeAfter: true,
            appendLeftRight: false,
            top: rect.top + scrollTop - paddingTop,
            left: rect.left - paddingLeft,
            width: rect.width,
            height: rect.height,
            isSlot,
            productId,
            articleId,
            parentTag,
            tag,
            marqueeItemKey,
          });
        }
      }
    }
  }

  return placeholders;
};

const getIframeBodyPadding = () => {
  const $iframe = document.body.querySelector<HTMLIFrameElement>(selector.iframe);
  const $iframeDoc = $iframe?.contentDocument || ($iframe?.contentWindow as any)?.document;
  if (!$iframeDoc) return { paddingLeft: 0, paddingTop: 0 };

  const computedStyle = getComputedStyle($iframeDoc.body);
  return {
    paddingLeft: parseInt(computedStyle.paddingLeft) || 0,
    paddingTop: parseInt(computedStyle.paddingTop) || 0,
  };
};

const getDragInformation = (
  state: {
    newPreset: null | ComponentPreset;
    timeoutDragging: NodeJS.Timeout | null;
    component: null | Component;
    rectDragging: RectDragging | null;
  },
  flowTag?: string,
):
  | {
      components: [Component] | InitComponentType[];
      label: string;
      isNew: boolean;
      rootOverride?: Record<string, any>;
    }
  | undefined => {
  if (state.newPreset?.id) {
    let components = state.newPreset.components;
    const flowTagComponents = state.newPreset?.flowTagComponents || {};
    if (flowTag && flowTagComponents && flowTagComponents[flowTag]) {
      components = flowTagComponents[flowTag] || [];
    }
    return {
      isNew: true,
      label: state.newPreset.name.en,
      components: [
        {
          ...components[0],
          rootOverride: state.newPreset?.rootOverride ?? undefined,
        },
      ],
    };
  } else if (state.component) {
    return {
      isNew: false,
      label: state.component.label || '',
      components: [state.component],
    };
  }
};

export const onOpenSetting = (
  componentUid: string,
  sectionId: string,
  productId: string,
  articleId?: string,
  marqueeItemKey?: string,
) => {
  const editorStore = useEditorStore();
  editorStore.setEditingComponentUid(componentUid);
  editorStore.setEditingSectionCid(sectionId);
  editorStore.setEditingProductId(productId);
  editorStore.setEditingArticleId(articleId ?? '');
  editorStore.setEditingMarqueeSelectedKey(marqueeItemKey);
};

const getChildrenByAttrSelector = ($el: Element, attrSelector: string) => {
  const childLen = $el.children.length;
  if (childLen) {
    for (let i = 0; i < childLen; i++) {
      const children = $el.children[i];
      if (children) {
        const is = children.getAttribute(attrSelector);
        if (is) {
          return children;
        }
      }
    }
  }
};
